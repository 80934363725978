@import "~scss/imports";

.AnnualReports {
  @extend %blockmarginvertical;
  @extend %max;
}

.AnnualReport {
  display: block;
  position: relative;

  .ImageBlock {
    margin: 0 15px;
  }

  @include tablet {
    min-height: 200px;
    min-width: 300px;

    &:hover .AnnualReport-wave {
      height: 135px;
    }
  }
}

.AnnualReport-wave {
  background-color: var(--black);
  bottom: 0;
  clip-path: url(#HeaderWaveClip);
  left: 0;
  height: 85px;
  position: absolute;
  right: 0;
  transform: rotate(180deg);
  transition: height 0.3s;

  @include tablet {
    height: 125px;
  }
}

.AnnualReport-year {
  bottom: 0;
  bottom: 28px;
  color: var(--white);
  font-size: var(--size-h3);
  font-weight: 500;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1;

  @include tablet {
    bottom: 35px;
  }
}

.AnnualReports-LoadMore {
  @extend %blockpaddingvertical;
  @extend %max;
  text-align: center;
}
