@import "~scss/imports";

.headerSection {

  --gradient: linear-gradient(
                  to left,
                  var(--blue1) -10%,
                  var(--orange1) 21%,
                  var(--blue1) 93%
  );

  background-image: var(--gradient);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-top: var(--nav-height);
  padding-bottom: calc(25px);
}

.headerSection-wrapped {
  //desktop
  @extend %max;
}

.headerSection h1 {
  text-transform: uppercase;
  text-align: center;

  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  font-size: 60px;
  font-weight:900;
}

.headerSection h2 {
  text-align: center;
  font-size: 20px;

}