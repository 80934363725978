@import "~scss/imports";

.logoBadgeContentBlock {
  @extend %blockmarginvertical;
  @extend %max;

  margin-top: calc(var(--block-margin) / 2);
  margin-bottom: calc(var(--block-margin) / 2);
}

.logoBadgeContentBlock .ImageBlock img {
  height: 75%;
}

.logoBadgeContentBlock .ImageBlock img {
  height: 75%;
}
