@import "~scss/imports";

.WaveCta {
  @extend %blockmarginvertical;
  align-items: center;
  display: grid;
  grid-template-areas:
    "heading"
    "text"
    "image"
    "link";
  grid-gap: 1em;

  @include tablet {
    grid-template-areas:
      ". image"
      "heading image"
      "text image"
      "link image"
      ". image";
    grid-template-rows: 1fr auto auto auto 1fr;
    grid-template-columns: repeat(2, 1fr);
  }
}

.WaveCta-heading,
.WaveCta-text,
.WaveCta-link {
  padding: 0 1rem;

  @include tablet {
    max-width: 460px;
    width: 100%;
    margin: 0 auto;
  }
}

.WaveCta-image-wave {
  // padding-top: 1em;

  img {
    width: calc(100% - 1em);
    margin-top: 2em;
    background-color: var(--teal2);
  }
  @include tablet {
    padding-left: 5vw;
    img {
      margin-top: 0;
    }
  }
}

.WaveCta-image-wave-image--mobile {
  position: absolute;
  z-index: -1;
  bottom: 0;
  transform: translateY(1em);
}

.WaveCta-image-wave-image--desktop {
  display: none;
}
@include tablet {
  .WaveCta-image-wave-image--desktop {
    display: block;
  }
  .WaveCta-image-wave-image--mobile {
    display: none;
  }
}

.WaveCta-image {
  grid-area: image;
  position: relative;
}
.WaveCta-image .Image {
  margin-left: auto;
  // opacity: 0.5;
  width: calc(100% - 1em);
  //height: calc(100% + 1em);

  @include tablet {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.WaveCta-heading {
  grid-area: heading;
  @extend %h2blackcaps;
}

.WaveCta-text {
  grid-area: text;
  @extend %p;
}

.WaveCta-link {
  grid-area: link;
  color: var(--blue3);
  display: block;
  margin-top: 1em;
}
