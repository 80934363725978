@import "~scss/imports";

.IconHeadingSet {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 1em;
}

.IconHeadingSet-item {
  display: grid;
  padding: 1em;
  grid-template-rows: auto 1fr;
  grid-gap: 1em;
}
.IconHeadingSet-item-icon {
  width: 100%;
  img {
    margin: 0 auto;
    max-width: 80px;

    @include tablet {
      max-width: 140px;
      width: 100%;
    }
  }
}

.IconHeadingSet-item-heading {
  //align-self: flex-end;
}
