@import "~scss/imports";
$breakpoint: 950px;

.CampaignHeader-heading {
  @extend %h2blackcaps;
  max-width: 12em;
}

.CampaignHeader-text {
  @extend %plarge;
}

.CampaignHeader-year {
  @extend %max;
  height: 0;
  padding-bottom: 60px;
}

.CampaignHeader-year-sticker {
  transform: translateY(-50%);

  width: 120px;
  height: 120px;
  background-image: url(~images/campaign_year_badge.svg);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend %h4;
  color: var(--blue3);
}

.CampaignHeader-inner {
  @extend %max;

  display: grid;
  margin-top: 2em;

  .CampaignHeader-text {
    margin-top: 1em;
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    .CampaignHeader-text {
      margin-top: 0;
    }
  }
}

.CampaignHeader-video {
  display: none;

  @media (min-width: $breakpoint) {
    width: 100%;
    display: block;

    video {
      width: 100%;
      margin: auto;
      max-height: 615px !important;
    }
  }
}

.CampaignHeader-image {
  @media (min-width: $breakpoint) {
    &.hidden-sm {
      display: none;
    }
  }
}

