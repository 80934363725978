@import "~scss/imports";

.DonateCta {
  //desktop

  display: grid;
  grid-template-areas:
    "heading"
    "text"
    "link";
  @include blockmarginifunwrapped;
  @extend %maxslim;

  .DonateCta-link {
    width: 100%;

    @media (min-width: 450px) {
      width: auto;
    }
  }

  @media (min-width: 600px) {
    grid-template-areas:
      "heading heading"
      "text link";
    align-items: start;
    grid-template-columns: 1fr auto;
    grid-row-gap: 11%;
    grid-column-gap: 1em;

    .DonateCta-link {
      width: unset;
      margin: 0 1.5em;
    }
  }

  .DonateCta-heading {
    @extend %bignumberheading;
  }
}

.DonateCtaWithImage {
  display: grid;
  grid-template-areas:
    "image"
    "heading"
    "text"
    "link";
  grid-gap: 1em;
  padding: 5vw;

  .DonateCta-heading {
    @extend %h2blackcaps;
  }

  @include tablet {
    grid-template-areas:
      "image . "
      "image heading"
      "image text"
      "image link"
      "image .";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto auto auto 1fr;
    grid-column-gap: 4%;
  }
}

.DonateCta-image {
  grid-area: image;
  align-self: center;
}

.DonateCta-heading {
  grid-area: heading;
}

.DonateCta-text {
  grid-area: text;
  @extend %p;
  max-width: 20em;
}

.DonateCta-link {
  grid-area: link;
  @extend %button;
  @extend %label;
  align-self: center;
  justify-self: start;
}
