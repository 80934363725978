@import "~scss/imports";

.WaveTextOnBackgroundImage {
  position: relative;
  display: flex;
  flex-direction: column;

  @include tablet {
    display: block;
  }
}

.WaveTextOnBackgroundImage-image {
  margin: 0;
  order: 1;

  .ImageBlock {
    height: 100%;
  }

  @include tablet {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;

    &:before {
      background-color: var(--black4);
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.4;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    img {
      min-height: 100%;
      object-fit: cover;
    }
  }

  @include desktop {
    max-height: 666px;
    overflow: hidden;
  }
}

.WaveTextOnBackgroundImage-heading {
  @extend %h2blackcaps;
  @include padding;

  order: 0;

  @include tablet {
    color: var(--white);
    padding-top: var(--block-margin);
    padding-bottom: var(--block-margin);
    width: 75%;
  }

  @include desktop {
    width: 66%;
  }
}
