@import "~scss/imports";

.MobileNavToggle {
  margin-left: auto;

  background-color: transparent;
  border: 0;
  border-radius: 0;
  @extend %navitem;
  font-weight: bold;

  &:focus {
    outline: 2px solid var(--teal2);
  }
  position: relative;
}

.MobileNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--teal4);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .Subscribe {
    margin-top: 1em;
  }
}

.MobileNav-top {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--teal4);
  padding-top: 1em;
  padding-bottom: 1em;
  @include padding;
}
.MobileNav-close {
  background-color: transparent;
  border: 0;
  border-radius: 0;

  &:focus {
    outline: 2px solid var(--teal2);
  }
}
.MobileNav-items {
  color: var(--white);
  margin: 0;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 2em;
  @include padding;

  margin-top: 1em;

  .AccordionItem-heading {
    @extend %navitem-mobile;
    position: static;

    &:focus,
    &:hover {
      color: var(--white);
    }
  }
  .AccordionItem-content ul {
    display: grid;
    grid-gap: 1em;
    padding: 0 1em 1em;
  }
}

.MobileNavItem-link,
.MobileNavItem-button {
  @extend %navitem-mobile;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid var(--teal2);
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  &:focus,
  &:hover {
    color: var(--white);
  }
}
.MobileNavItem-button {
  background-color: transparent;
  border: unset;
  border-top: 2px solid var(--teal2);
  width: 100%;
  text-align: left;
  color: inherit;

  &:focus {
    outline: 0;
    svg {
      stroke: var(--teal2);
      * {
        stroke: inherit;
      }
    }
  }
}
.MobileNavItem-button-carrot {
  width: 0.7rem;
  transition: transform 0.3s;
  .MobileNavItem-button--open & {
    transform: rotate(90deg);
  }
}

.MobileNavItem-sublink {
  @extend %navitem-mobile;
  font-weight: normal;
  text-transform: unset;
}

.MobileNav-waves {
  margin-left: auto;
  margin-top: auto;
  flex-shrink: 0;
}
