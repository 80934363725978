@import "~scss/imports";

.GradientWorm {
  position: relative;
  padding-bottom: var(--block-margin);
}

.Page > .GradientWorm:first-child {
  padding-top: var(--block-margin);
}

.GradientWorm-worm {
  position: absolute;
  z-index: -1;
  max-width: 1600px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
}
.GradientWorm:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    var(--white),
    var(--yellow1),
    var(--teal1)
  );
  opacity: 0.3;
  z-index: -1;
}

.GradientWorm--yellow:after {
  background-image: linear-gradient(
    to top,
    var(--white),
    var(--yellow1),
    var(--teal1)
  );
}
