@import "~scss/imports";

@mixin filter--right {
  img {
    -webkit-mask: linear-gradient(to right, black 70%, transparent 100%);
  }
}
@mixin filter--top {
  img {
    -webkit-mask: linear-gradient(to top, black 75%, transparent 100%);
  }
}
@mixin filter--left {
  img {
    -webkit-mask: linear-gradient(to left, black 80%, transparent 100%);
  }
}

.ImageRotateText {
  display: grid;
  grid-template-areas:
    "text"
    "image2"
    "image1"
    "image3";
  @extend %blockmarginvertical;
  .ImageRotateText-text > div {
    text-shadow: 0 0 0.25em white;
  }

  @include tablet {
    grid-template-areas:
      "image1 text image3"
      ". image2 .";
    grid-template-columns: 6fr 6fr 3fr;
    grid-template-rows: 30vh 1fr;

    .ImageRotateText-text > div {
      width: 150%;
      margin-left: -60%;
    }

    .ImageRotateText-image1 {
      margin-top: 0;
      width: 100%;
      @include filter--right;
    }
    .ImageRotateText-image2 {
      margin-top: 0;
      padding-right: 0;
      width: 70%;
      margin-left: 15%;
      margin-right: 10%;
      @include filter--top;
    }
    .ImageRotateText-image3 {
      display: block;
      margin-top: 0;
      width: 100%;
      justify-self: start;
      @include filter--left;
    }
  }

  @include desktop {
    grid-template-columns: 4fr 4fr 2fr;
    grid-template-rows: 35vh 1fr;
  }
}

.ImageRotateText-image1 {
  grid-area: image1;
  width: 66%;
  margin-top: -20%;
  z-index: 2;
}

.ImageRotateText-image2 {
  grid-area: image2;
  width: 50%;
  margin-left: auto;
  margin-top: -5%;
  z-index: 3;
  padding-right: 1rem;
  @include filter--top;
}
.ImageRotateText-image3 {
  display: none;
  grid-area: image3;
  width: 66%;
  margin-left: auto;
  margin-top: -5%;
  z-index: 1;
}
.ImageRotateText-text {
  display: grid;
  grid-template-columns: fit-content(800px) auto;
  @extend %imagerotateheading;
  padding: 0 1rem;
  max-width: 15em;
  grid-gap: 10px;
  z-index: 4;
}

.ImageRotateText-text > div
{
  text-align: center;
}

.ImageRotateText-text p {
  margin: 0;
  padding: 0;
}

.ImageRotateText .ImageRotateText-text .ImageRotateText-text-line1,
.ImageRotateText .ImageRotateText-text .ImageRotateText-text-line4,
{
  align-content:end;
}
.ImageRotateText .ImageRotateText-text .ImageRotateText-text-line1,
.ImageRotateText .ImageRotateText-text .ImageRotateText-text-line2,
{
  width: 800px;
  text-align: right;
}

.ImageRotateText .ImageRotateText-text .ImageRotateText-text-line4,
.ImageRotateText .ImageRotateText-text .ImageRotateText-text-line3,
{
  text-align: left;
  margin-left: -475px;
}

.ImageRotateText .ImageRotateText-text .ImageRotateText-text-line3
{
  overflow: hidden;
}

.ImageRotateText-text-line3-rotating {
  position: absolute;
  opacity: 0;
}

.ImageRotateText-text-line3-rotating.active {
  opacity: 1;
  animation: slideUpDown 2s forwards;
  transform: translateY(100%);
}

@keyframes slideUpDown {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@media only screen and (max-width: 768px) {
  .ImageRotateText .ImageRotateText-text .ImageRotateText-text-line1,
  .ImageRotateText .ImageRotateText-text .ImageRotateText-text-line2,
  {
    width: 175px;
  }

  .ImageRotateText .ImageRotateText-text .ImageRotateText-text-line4,
  .ImageRotateText .ImageRotateText-text .ImageRotateText-text-line3,
  {
    text-align: left;
    margin-left: 0px;
  }
}

.ImageRotateText-rotating-safari-fix {
  opacity: 0.001;
}

.ImageRotateText-safari-fix {
  opacity: 0.001;
}
