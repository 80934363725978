@import "~scss/imports";

.ImageCaption {
  @extend %blockmarginvertical;
}

.ImageCaption-image {
}

.ImageCaption-caption {
  margin-top: 0.5em;
  color: var(--teal3);
}
