@import "~scss/imports";

.anchorLinksContentBlock {
  --gradient: linear-gradient(
                  to left,
                  var(--blue1) -10%,
                  var(--orange1) 21%,
                  var(--blue1) 93%
  );
  width: 100%;
  min-height: calc(var(--nav-height) + 1px);

  background-image: var(--gradient);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.anchorLinksContentBlockMobile {
  height: calc(var(--nav-height) + 100px);
}

.anchorLinksContentBlockSticky {
  position: sticky;
  top: var(--nav-height);
  z-index: 1000;
}

.anchorLinksContentBlockDesktop {

}

.mobileAnchorLinkBlockLink {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 24px;
  text-transform: uppercase;
}

.mobileAnchorLinkBlockLink span {
  width: 24px;
  height: 24px;
}

.anchorLinksContentBlock-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;

  @media all and (-ms-high-contrast: none) {
    background-image: linear-gradient(90deg, rgb(229, 248, 255) -10%, rgb(255, 243, 232) 21%, rgb(229, 248, 255) 93%);
  }
}

.anchorLinksContentBlock-inner {
  @extend %max;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  width: 100%;
}

.mobileAnchorLinksBlock {
  opacity: 1;
  height: 80px;
  width: 100%;
  text-align: center;
}

.mobileAnchorLinksBlockLink {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 24px;
  text-transform: uppercase;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileAnchorLinksBlockIsOpenedImage.closed {
  transform: rotate(180deg);
}

.mobileAnchorLinksBlock-Popup-Content {
  padding-top: 11px;
  padding-left: 16px;
}

.mobileAnchorLinkBlock {
  margin-bottom: 13px;
}

.mobileAnchorLinkBlockLink {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.2px;
  text-align: left;
  text-decoration: none;
  padding-bottom: 23px;
}

.desktopAnchorLinkBlockLink {
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  height: 80px;
  width: 100%;
}

.desktopAnchorLinkBlockLink.desktopAnchorLinkBlockLinkSelected
{
  background-color: #003046;
  color: white;
}

.desktopAnchorLinksBlock {
  display: grid;
  grid-gap: 1em;
}

.desktopAnchorLinkBlockLink {
  position: relative;
  vertical-align: middle;
  transition: color .25s;
}

.desktopAnchorLinkBlockLink {
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.2px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.anchorLinksContentBlock-inner .Grid {
  align-items: center;
}

.mobileAnchorLinksBlock-Popup {
  position: absolute;
  top: calc(100% - 2em);
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;
  z-index: 1000;
  width: 80%;
}