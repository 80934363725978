@import "~scss/imports";

.yourDataContentBlock {
  //desktop

  @extend %max;
  @extend %blockpaddingvertical;

  margin-bottom: 0;
  padding-bottom: 0;
}

.yourDataContentBlockHeader {
  text-align: center;
  margin-bottom: 60px;
}

.yourDataContentBlockHeader h2 {
  position: relative;
  display: inline-block;

  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 900;
  font-style: Black;
  letter-spacing: 0.4px;
  line-height: 56px;
  text-transform: uppercase;
}

.yourDataContentBlockHeader h2:after {
  content: '';
  position: absolute;
  bottom: -6.83px;
  left: 0;
  width: 100%;
  height: 6.83px;
  background-image: url("./wave.svg");
}

.yourDataContentBlockItem-Icon {
  width: 70px;
}

.yourDataContentBlockItem-Header {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.3px;
  text-align: left;
  line-height: 40px;
}

.yourDataContentBlockItem-Header h4 {
  margin-top: 10px;
  margin-bottom: 0px;
}

.yourDataContentBlockItem-Text {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 32px;
}

.yourDataContentBlock .Grid-item a {
  display: inline;
  text-decoration: underline;
}
