@import "~scss/imports";

.ProfileCtaNoImage {
  --gutter: 34px;
  background-color: var(--lightgreen);
  @extend %wavegradientbg;
}
.ProfileCtaNoImage-clip {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ProfileCtaNoImage-inner {
  @extend %max;
  position: relative;
  z-index: 1;
  padding: 12.5% 10%;
}

.ProfileCtaNoImage-text {
  grid-area: text;
  display: grid;
  grid-gap: 2em;
}

.ProfileCtaNoImage-heading {
  @extend %bigquote;
}
.ProfileCtaNoImage-attribution {
}

.ProfileCtaNoImage-link {
  justify-self: end;
}
