@import "~scss/imports";

.ProgramValues {
  list-style-type: none;
  padding-left: unset;
  background-color: var(--teal1);
  @extend %modulepadvertical;
}

.ProgramValues-inner {
  @extend %maxmobile;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .ProgramValuesItem {
    width: 100%;

    @include desktop {
      width: 33.33%;
    }
  }
}

.ProgramValuesItem {
  @include desktop {
    padding-right: 3%;
  }

  & + .ProgramValuesItem {
    margin-top: 2em;

    @include desktop {
      margin-top: unset;
    }
  }
}
.ProgramValuesItem-heading {
  @extend %h4;
}

.ProgramValuesItem-text {
  @extend %p;
  margin-top: 1em;
}
