@import "~scss/imports";

.SidebarList {
  //padding: 0 1em;
  padding-left: 1em;
  padding-right: 1em;
}

.SidebarList-count {
  @extend %formlabel-mono;
  margin-top: 1em;
}

.SidebarList-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  display: grid;
  grid-row-gap: 1em;
  margin-top: 1em;
  overflow-x: auto;
}

.SidebarList-item {
  padding-right: 1em;
  padding-bottom: 1em;

  transition: padding-left 0.3s, background-color 0.3s;
  &:hover {
    padding-left: 1em;
    background-color: var(--blue1);
  }
  &.active {
    border-left: 2px solid;
    padding-left: 0.5em;
  }
}
.SidebarList-item-toggle {
  background-color: transparent;
  padding: 0;
  border: 0;
  width: 100%;
  text-align: unset;
  font: unset;
}

.Sidebar-markerdetail {
  padding: 0 1em;

  .ArrowBackButton {
    margin-bottom: 1em;
  }
}

.NoLocationsInView {
  display: grid;
  grid-gap: 2em;
  margin-top: 2em;
}
.NoLocationsInView-item {
  display: grid;
  grid-gap: 1em;
}
.NoLocationsInView-item-message {
  @extend %p;
}
.NoLocationsInView-item-link {
}
