@import "~scss/imports";

.TeamsGroup {
  @extend %blockpaddingvertical;

  &:nth-of-type(odd) {
    background-color: transparent;
  }

  &:nth-of-type(4n) {
    background-color: var(--blue1);
  }

  &:nth-of-type(4n + 2) {
    background-color: var(--yellow1);
  }
}

.Teams-heading {
  @extend %maxmobile;

  h2 {
    @extend %h2blackcaps;
    margin-top: 0;
  }
}

.TeamsGroup-list {
  @extend %maxmobile;
}

.TeamsGroup-list-item {
  padding: 24px;
  width: 100%;
}

.TeamsGroup-list-item-heading {
  margin: 1rem 0 0.5rem;
  @extend %h4black;
}
.TeamsGroup-list-item-copy {
  margin: 0.5rem 0 0;
}
