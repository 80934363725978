.topScrollButtonBlock {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.topScrollButtonBlock span {
  opacity: 1;
  color: #013046;
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 900;
  font-style: Black;
  letter-spacing: 0.4px;
  line-height: 56px;
  text-transform: uppercase;
}
