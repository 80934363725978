@import "~scss/imports";
$breakpoint: 1100px;

.CookieModal {
  color: #fff;
  padding: 5px 40px;
  background-color: var(--teal3);
  font-size: var(--size-p);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;

  &.hidden {
    display: none;
  }
}

.CookieModal-about-sm {
  display: none;
  font-size: var(--size-alertbartext);
  font-family: var(--mono);

  @media (max-width: $breakpoint) {
    display: inline-block;
    color: var(--black);
    font-weight: bold;
    text-decoration: none;
    margin: 14px 0;
    outline: none !important;
  }
}

.CookieModal-text {
  display: inline-block;
  max-width: 85%;
  text-align: left;
  font-family: var(--mono);
  line-height: 45px;
  font-size: var(--size-alertbartext);

  @media (max-width: $breakpoint) {
    display: block;
    max-width: 100%;
    margin-bottom: 10px;
    line-height: 22px;
  }

  a {
    color: var(--black);
    text-decoration: none;
    margin-left: 20px;
    font-weight: bold;
    outline: none !important;
    font-family: var(--mono);

    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  p {
    margin: 0px;
  }
}

.CookieModal-accept {
  @extend %button;
  @extend %buttonlabel;
  padding: 1em 2.5em;
  min-width: auto;
  float: right;
  color: var(--black);
  background-color: var(--teal2);
  box-shadow: inset 0 0 0 2px var(--black);
  width: auto;
  font-family: var(--mono);
  font-weight: 700;
  font-size: 12px;
}

.CookieModal-inner {
  max-width: 1150px;
  margin: auto;
}
