@import "~scss/imports";

.ClubMap {
  display: -ms-grid;
  display: grid;
  height: calc(100vh - var(--nav-height));
  -ms-grid-columns: 1fr 1fr 1fr;
  --controls-bg: linear-gradient(to bottom, rgba(215, 249, 244, 0.5), #ffffff);
}

.ClubMap--static {
  grid-template-areas: "query" "map";
  grid-template-rows: auto 1fr;
}
.ClubMap--interactive {
  grid-template-areas:
    "query"
    "map"
    "mobileresults";
  grid-template-rows: auto 1fr;

  @include tablet {
    --sidebar-width: 376px;
    grid-template-columns: var(--sidebar-width) 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "query filter"
      "sidebar map";
  }

  @media (min-width: 1100px) {
    grid-template-columns: var(--sidebar-width) 1fr 1fr;
    grid-template-areas:
      "query filter contact"
      "sidebar map map";

    .ClubMap-contact {
      display: flex;
    }
  }
}

.ClubMap-mobileresults {
  overflow: auto;
  padding: 1em;
  max-height: 200px;
}

.ClubMap-query {
  background-image: var(--controls-bg);
  grid-area: query;
  @extend %formlabel-mono;
  padding: 2em 1em;
  display: flex;
  z-index: 3;
  -ms-grid-row: 1;
  -ms-grid-column: 1;

  > label {
    width: 100%;
  }

  .PlacesAutocomplete {
    position: relative;
    @extend %textinput;
    //max-width: 366px;
  }
  .PlacesAutocomplete-results {
    box-sizing: content-box;
    position: absolute;
    top: calc(100% - 2px);
    border: 2px solid var(--teal3);
    width: 100%;
    left: -1px;
  }
  .PlacesAutocomplete-input {
    svg {
      float: right;
      margin-top: -28px;
    }
  }
  .PlacesAutocomplete-loading {
    position: absolute;
    right: 0;
    top: 0.5em;
    display: flex;
    align-items: center;
    font-size: 2em;
    z-index: 2;
  }
  .PlacesAutocomplete-results-dropdown {
    width: 100%;
  }
  .PlacesAutocomplete-results-dropdown-item {
    padding: 0.5em;
  }
  .PlacesAutocomplete-results-dropdown-item-main {
  }
  .PlacesAutocomplete-results-dropdown-item-secondary {
    color: gray;
    font-size: 75%;
  }
  .PlacesAutocomplete-results-dropdown-item--active {
    padding: 0.5em;
    background-color: gray;
  }
  .PlacesAutocomplete-results-dropdown-item,
  .PlacesAutocomplete-results-dropdown-item--active {
    & + .PlacesAutocomplete-results-dropdown-item,
    & + .PlacesAutocomplete-results-dropdown-item--active {
      border-top: 1px solid #e6e6e6;
    }
  }
}
.ClubMap-filter {
  background-image: var(--controls-bg);
  grid-area: filter;
  padding: 2em 0;
  display: flex;
  justify-content: center;
  -ms-grid-row: 1;
  -ms-grid-column: 2;

  .fieldset-heading {
    @extend %formlabel-mono;
  }

  .Checkbox {
    @extend %formlabel;
    &:focus-within {
      outline: 2px solid var(--teal2);
    }
    & + .Checkbox {
      margin-top: 1em;
    }

    input {
      margin-right: 0.25rem;
      &:focus {
        outline: 0;
      }
    }
  }
}
.ClubMap-contact {
  background-image: var(--controls-bg);
  grid-area: contact;
  @extend %plarge;
  display: none;
  align-items: center;
  justify-content: center;
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}

.ClubMap-sidebar {
  grid-area: sidebar;
  position: relative;
  overflow-y: auto;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
}

.ClubMap-map {
  grid-area: map;
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
}

.ClubMap-map-marker {
  --size: 60px; //40px
  font-family: var(--mono);
  font-size: var(--size-p);
  font-weight: 900;
  line-height: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  color: white;

  cursor: pointer;
  position: relative;

  .ClubMap-map-marker-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border-color: var(--blue4);
    border-width: 1px;
    border-style: solid;
    padding: 0.25em;
    mix-blend-mode: multiply;
  }

  .MapMarkerIcon {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  &.single {
    --size: 20px;
  }
}

.ClubMeta {
  letter-spacing: 0.85px;
  line-height: 1.3;
  display: grid;
  justify-items: flex-start;
  grid-gap: 0.5em;
}

.ClubMeta-type {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 18px;
}

.ClubMeta-title {
  font-size: 18px;
  font-weight: 900;
}
.ClubMeta-count {
  font-size: 14px;
}
.ClubMeta-email {
  display: flex;
}
.ClubMeta-email-address {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(var(--sidebar-width) - 5.2rem);
  //this is the exact space of the "copied button"
}

.ClubMetaDetails .ClubMeta-address {
  margin-top: 1em;
}

.fieldset-heading {
  margin-bottom: 1em;
}

.MarkerTooltip {
  padding: 0.5em;
  display: grid;
  grid-gap: 0.5em;
  max-width: 320px;
}
