@import "~scss/imports";
.ProgramApplication-body {
  background-color: var(--teal1);
}
.ProgramApplication-body-inner {
  @extend %max;
  @extend %blockpaddingvertical;
}

.ProgramApplication-body-links {
  margin-top: 4em;
  display: grid;
  grid-gap: 1em;

  @include tablet {
    justify-content: center;
  }
}
