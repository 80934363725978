@import "~scss/imports";

.CopyButton {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-size: 12px;
  color: var(--teal4);

  &:focus {
    outline: 0;
  }
}
