@import "~scss/imports";

.Grid {
  @extend %list-reset;
  display: flex;
  flex-wrap: wrap;
}

.centered {
  justify-content: center;
}

.Grid-item {
  display: flex;
  margin-right: 1.9%;
  margin-bottom: 2em;
  width: 100%;

  a {
    color: inherit;
    display: flex;
    flex-direction: column;
    text-decoration: unset;
    width: 100%;
  }

  @include tablet {
    width: calc(50% - 0.95%);

    &:nth-of-type(2n) {
      margin-right: 0;
    }

    &.limitFive {
      width: calc(20% - 1.45%);

      &:nth-of-type(2n),
      &:nth-of-type(3n),
      &:nth-of-type(4n) {
        margin-right: 1.6%;
      }

      &:nth-of-type(5n) {
        margin-right: 0;
      }
    }
  }

  @include desktop {
    width: calc(25% - 1.45%);

    &:nth-of-type(2n) {
      margin-right: 1.9%;
    }

    &:nth-of-type(4n) {
      margin-right: 0;
    }


    &.limitTwo {
      width: calc(50% - 30px);

      &:nth-of-type(n){
        margin-right: 28px;
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }

    &.third {
      width: calc(33% - 0.95%);

      &:nth-of-type(2n),
      &:nth-of-type(4n) {
        margin-right: 1.9%;
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }

    &.limitFourth {
      width: calc(25% - 1.45%);

      &:nth-of-type(2n),
      &:nth-of-type(3n) {
        margin-right: 1.9%;
      }

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }

    &.limitFive {
      width: calc(20% - 1.45%);

      &:nth-of-type(2n),
      &:nth-of-type(3n),
      &:nth-of-type(4n) {
        margin-right: 1.6%;
      }

      &:nth-of-type(5n) {
        margin-right: 0;
      }
    }
  }

  &.border--teal2 {
    border: 2px solid var(--teal2);
  }
}
