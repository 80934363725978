@import "~scss/imports";

$breakpoint: 950px;

.PartnerLogos {
  background-color: var(--teal1);
}

.PartnerLogos-inner {
  @extend %max;
  padding-top: 60px;
  padding-bottom: 60px;
}

.PartnerLogos-grid {
  display: grid;
  grid-template-areas:
          "leftside "
          "partners ";
  grid-template-columns: 1fr;
  grid-gap: 2em;

  @media (min-width: 600px) {
    grid-template-areas:
            "leftside leftside"
            "partners partners";
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em 5em;
  }

  @media (min-width: $breakpoint) {
    grid-template-areas:
            "leftside partners";
    grid-template-columns: 43% 50%;
    grid-gap: 10%;
    align-items: start;
    padding-right: 2vw;
  }
}

.PartnerLogos-leftside,
.PartnerLogos-text {
  max-width: 32rem;
  width: 100%;
  @media (min-width: $breakpoint) {
    margin-left: auto;
    margin-right: auto;
  }
}
.PartnerLogos-leftside {
  grid-area: leftside;

  .inner-head {
    @extend %h2blackcaps;
  }

  .inner-text {
    @extend %plarge;
    text-transform: none;
    margin-top: 30px;
  }
}

.PartnerLogos-text {
  @extend %plarge;
  grid-area: text;
}

.PartnerLogos-partners {
  grid-area: partners;
  text-align: center;

  @media (min-width: $breakpoint) {
    max-width: 512px;
  }

  .link {
    float: left;
    margin-top: 40px;
    width: 100%;

    @media (min-width: $breakpoint) {
      > div {
        margin: auto;
        padding-left: 50px;
      }
    }
  }

  > svg {
    display: inline-block;
    vertical-align: middle;
    max-height: 60px;
    max-width: 125px;
    height: auto;
    width: auto;
    margin-right: 30px;
    margin-bottom: 25px;

    @media (min-width: $breakpoint) {
      max-width: 225px;
    }
  }
}
