@import "~scss/imports";

.ClubMapCta {
  display: grid;
  grid-template-areas:
    "heading"
    "image"
    "text"
    "link";
  @extend %blockmarginvertical;
  padding-left: 1em;
  padding-right: 1em;
  grid-gap: 1em;

  max-width: 1750px;
  margin-left: auto;
  margin-right: auto;

  @include tablet {
    grid-template-areas:
      "image ."
      "image heading"
      "image text"
      "image link"
      "image .";
    grid-template-columns: 1fr 300px;
    grid-template-rows: 1fr auto auto auto 1fr;
    grid-column-gap: 4vw;
    padding-left: 2vw;
    padding-right: 2vw;

    .ClubMapCta-cta {
      max-width: 286px;
    }
  }
}

.ClubMapCta-cta {
  align-self: center;
}
.ClubMapCta-image {
  grid-area: image;
}
.ClubMapCta-heading {
  grid-area: heading;
  @extend %h2blackcaps;
}

.ClubMapCta-text {
  grid-area: text;
  @extend %p;
}

.ClubMapCta-link {
  grid-area: link;
}
