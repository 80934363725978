@import "~scss/imports";

.WaysToFundraise-inner {
  @extend %max;
  @extend %blockpaddingvertical;
}

.WaysToFundraise-heading {
  @extend %h2blackcaps;
}

.WaysToFundraise-links {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin-top: 1em;
  }
  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 2px solid;
  }
}

.WaysToFundraise-iconitems {
  .IconHeadingSet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;

    @include desktop {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .IconHeadingSet-item-heading {
    @extend %buttonlabel;
    margin: 0 auto;
    text-align: center;

    .IconHeadingSet-item-link {
      margin-top: 12px;
      text-transform: none;
      text-align: left;

      .ArrowLink {
        font-size: 17px;
      }
    }
  }
}

.WaysToFundraise-subheading {
  @extend %h3caps;
  margin-top: 3em;
}

.WaysToFundraise-text,
.WaysToFundraise-links {
  @extend %plarge;
  margin-top: 1em;
}
