.message-button {
  padding: 1em;
  border-radius: 0;
  border: 1px solid;
  margin: 0.5em;
}

.message-input {
  padding: 1em;
  display: block;
  width: 100%;
}

.TextWaveSandbox {
  display: grid;
  grid-template-columns: 400px 1fr;
}

.TextWaveSandbox-controls {
  background-color: var(--teal1);
  padding: 1em;
  height: 100vh;
  position: sticky;
  top: 0;

  input[type="range"] {
    width: 100%;
  }

  button {
    background-color: unset;
    cursor: pointer;
    display: block;
    width: 100%;
    margin: 1em 0;
  }
}

.TextWaveSandbox-message {
  width: 100%;
}

.TextWaveSandbox {
  .ImageWaveText-text-inner {
    border: 1px solid;
  }
}
