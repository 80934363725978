@import "~scss/imports";

.PageAlert {
  background-color: var(--blue3);
  color: var(--white);
  position: relative;
  padding: 1.5em;
  overflow: hidden;
  width: 100%;
  z-index: 2;

  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: auto;
  grid-template-areas:
    "icon heading"
    "text text"
    "link link";

  @include tablet {
    left: 100%;
    padding: 1.5em 1.5em 1.5em 3em;
    position: sticky;
    top: calc(50% - var(--nav-height));
    width: 300px;
  }
}

.PageAlert-toggle {
  background-color: transparent;
  border: none;
  bottom: 0;
  display: none;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 3em;

  span {
    border-left: 2px solid var(--teal2);
    border-top: 2px solid var(--teal2);
    display: block;
    height: 15px;
    left: 10px;
    transform: rotate(135deg);
    transition: transform 0.3s;
    position: absolute;
    width: 15px;

    &.switch {
      transform: rotate(-45deg) translateX(10px);
    }
  }


  @include tablet {
    display: block;
  }
}

.PageAlert-heading {
  @extend %h4black;
  grid-area: heading;
}
.PageAlert-icon {
  grid-area: icon;
  padding-right: 10px;
}
.PageAlert-body {
  @extend %p;
  @extend %psmall;
  grid-area: text;
}
.PageAlert-link {
  grid-area: link;
}
