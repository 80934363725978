@import "~scss/imports";

.Error {
  min-height: calc(100vh - var(--nav-height));
  @extend %wavegradientbg;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Error-inner {
  @extend %max;
  @extend %label;

  position: relative;
  z-index: 2;
}
