.Checkbox {
  align-items: center;
  display: flex;
}

.Checkbox input {
  --size: 14px;
  box-sizing: content-box;
  appearance: none;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
}

.CheckboxSet {
  border: 0;
  padding: 0;
  margin: 0;
}
