@import "~scss/imports";

.SolidBackground {
  @extend %blockpaddingvertical;
  > div {
    margin-top: unset;
    margin-bottom: unset;
  }
}

.SolidBackground--teal {
  background-color: var(--teal1);
  overflow: hidden;
}

.SolidBackground--blue {
  background-color: var(--blue1);
}
