@mixin padding {
  // padding-left: 5vw;
  // padding-right: 5vw;
  padding-left: 1rem;
  padding-right: 1rem;

  @include tablet {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
%max {
  max-width: var(--max-desktop);
  margin: auto;
  @include padding;
}
%maxmobile {
  max-width: var(--max-mobile);
  margin: auto;
  @include padding;

  @include desktop {
    max-width: var(--max-desktop);
  }
}
%maxslim {
  max-width: 865px;
  margin: auto;
  @include padding;
}
%maxarticle {
  max-width: 776px;
  margin: auto;
  @include padding;
}
%maxlarge {
  max-width: 2100px;
  margin: auto;
}
%max-shortp {
  max-width: var(--max-shortp);
}
%max-explainer {
  max-width: var(--max-explainer);
}

%blockmarginvertical {
  margin-top: var(--block-margin);
  margin-bottom: var(--block-margin);
}
%blockpaddingvertical {
  padding-top: var(--block-margin);
  padding-bottom: var(--block-margin);
}

%modulepadvertical {
  padding-top: var(--module-padding);
  padding-bottom: var(--module-padding);
}
