@import "~scss/imports";

.CampaignPullquote {
  position: relative;
  @extend %blockpaddingvertical;
  overflow-x: hidden;
}

.CampaignPullquote-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-75%, -50%);
  height: 120%;
  width: auto;
  z-index: -1;
  @include desktop {
    transform: translate(-50%, -50%);
    height: auto;
    width: 100%;
  }
}

.CampaignPullquote-inner {
  @extend %max;
  display: grid;
  align-items: center;
  grid-template-areas:
    "image"
    "text"
    "attribution";
  grid-gap: 1em;

  @include desktop {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "image text"
      "image attribution";

    .CampaignPullquote-image {
      padding-right: 18%;
    }
  }
}
.CampaignPullquote-image {
  grid-area: image;
}
.CampaignPullquote-text {
  @extend %campaignquote;
  grid-area: text;
  color: var(--blue3);
}

.CampaignPullquote-attribution {
  @extend %p;
  grid-area: attribution;
  margin-top: 1em;
}
