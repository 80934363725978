@import "~scss/imports";
.SubscribeDownload {
  position: relative;
}
.SubscribeDownload-inner {
  display: grid;
  grid-gap: 1em;
  @include tablet {
    grid-gap: 2em 4em;
  }
}

//student globals
.SubscribeDownload--student {
  @extend %max;
  @extend %blockmarginvertical;

  padding: 4em 3em;
  @media (min-width: 500px) {
    padding: 6em 4em;
  }

  .SubscribeDownload-label-text {
    text-transform: uppercase;
    color: var(--orange3);
  }
}
//adult globals {
.SubscribeDownload--adult {
  .SubscribeDownload-inner {
    @extend %max;
    padding-top: 1em;
    padding-bottom: 4em;

    @include tablet {
      padding-top: 4em;
      padding-bottom: 4em;
    }
  }
  .SubscribeDownload-label {
    grid-area: label;
  }
  .SubscribeDownload-label-inner {
    background-color: var(--teal2);
    padding: 0.66em 1.5em;
    display: inline-flex;
    @extend %label;
  }
}

.SubscribeDownload--withimage {
  .SubscribeDownload-heading {
    @extend %h2blackcaps;
  }
}
.SubscribeDownload--noimage {
  .SubscribeDownload-heading {
    @extend %h1caps;
  }
}

.SubscribeDownload--adult.SubscribeDownload--withimage
  .SubscribeDownload-inner {
  grid-template-areas:
    "image"
    "heading"
    "text"
    "form";

  @include tablet {
    grid-template-areas:
      "image heading"
      "text text"
      "form form";
    grid-column-gap: 2em;
    grid-template-columns: 1fr 1fr;

    .SubscribeDownload-heading {
      align-self: center;
    }

    .Subscribe {
      display: grid;
      grid-template-areas: "age input" "age submit";
      max-width: 100%;
      .Input {
        margin-top: 0;
        max-width: var(--max-subscribe-width);
      }
      .Subscribe-submit {
        max-width: var(--max-subscribe-width);
      }
    }
  }

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      "image heading"
      "image text"
      "image form";

    .Subscribe {
      display: block;
      max-width: var(--max-subscribe-width);
      .Input {
        margin-top: 1em;
      }
    }
  }

  .SubscribeDownload-label {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.SubscribeDownload--adult.SubscribeDownload--withimage.SubscribeDownload--hasdownload.SubscribeDownload--SUCCESS
  .SubscribeDownload-inner {
  grid-template-areas:
    "image"
    "heading"
    "text"
    "form"
    "download";

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      "image form"
      "image heading"
      "image text"
      "image download";
  }
}

//Adult - No Image - with Label
.SubscribeDownload--adult.SubscribeDownload--noimage .SubscribeDownload-inner {
  grid-template-areas:
    "label"
    "heading"
    "text"
    "form";

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      "label ."
      "heading form"
      "text form";
  }
}
.SubscribeDownload--adult.SubscribeDownload--noimage.SubscribeDownload--hasdownload.SubscribeDownload--SUCCESS
  .SubscribeDownload-inner {
  grid-template-areas:
    "label"
    "heading"
    "text"
    "form"
    "download";

  @include tablet {
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      "label ."
      "heading form"
      "text form"
      "download form";
  }
}

//Adult - No Image - No Label
.SubscribeDownload--adult.SubscribeDownload--noimage.SubscribeDownload--nolabel
  .SubscribeDownload-inner {
  grid-template-areas:
    "heading"
    "text"
    "form";

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "heading form"
      "text form";
  }
}
.SubscribeDownload--adult.SubscribeDownload--noimage.SubscribeDownload--hasdownload.SubscribeDownload--nolabel.SubscribeDownload--SUCCESS
  .SubscribeDownload-inner {
  grid-template-areas:
    "heading"
    "text"
    "form"
    "download";

  @include tablet {
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      "heading form"
      "text form"
      "download form";
  }
}

.SubscribeDownload--adult.SubscribeDownload--noimage.SubscribeDownload--SUCCESS
  .SubscribeDownload-inner {
  @include tablet {
    grid-template-rows: auto auto auto;
  }
}

.SubscribeDownload--student.SubscribeDownload--withimage {
  .SubscribeDownload-inner {
    grid-template-areas:
      "image"
      "heading"
      "text"
      "form";

    @include tablet {
      grid-template-areas:
        "image heading"
        "image text"
        "image form";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto 1fr;
    }
  }
}
.SubscribeDownload--student.SubscribeDownload--withimage.SubscribeDownload--hasdownload.SubscribeDownload--SUCCESS
  .SubscribeDownload-inner {
  grid-template-areas:
    "image"
    "heading"
    "text"
    "form"
    "download";
  @include tablet {
    grid-template-areas:
      "image heading"
      "image text"
      "image form"
      "image download";
    grid-template-rows: auto auto auto 1fr;
  }
}

.SubscribeDownload--student.SubscribeDownload--noimage
  .SubscribeDownload-inner {
  grid-template-areas:
    "heading"
    "text"
    "form";

  .SubscribeDownload-heading {
    max-width: 24em;
    margin: 0 auto;
    text-align: center;
  }

  @include tablet {
    grid-template-columns: 1fr 6fr 1fr 6fr 1fr;
    grid-column-gap: 0;
    grid-template-areas:
      ". heading heading heading ."
      ". text . form .";
  }
}
.SubscribeDownload--student.SubscribeDownload--noimage.SubscribeDownload--hasdownload.SubscribeDownload--SUCCESS
  .SubscribeDownload-inner {
  grid-template-areas:
    "heading"
    "text"
    "form"
    "download";
  @include tablet {
    grid-template-areas:
      ". heading heading heading ."
      ". text . form ."
      ". download . form .";
    grid-template-rows: auto auto auto 1fr;
  }
}

.SubscribeDownload-heading {
  grid-area: heading;
}
.SubscribeDownload-text {
  @extend %p;
  grid-area: text;
}
.SubscribeDownload-image {
  grid-area: image;
  position: relative;
}
.SubscribeDownload-form {
  grid-area: form;
}
.SubscribeDownload-download {
  grid-area: download;
}

.SubscribeDownload-download {
  align-self: start;
}

.SubscribeDownload .LabelFrame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.SubscribeDownload--student .SubscribeDownload-label {
  position: absolute;
  display: flex;
  align-items: center;
  transform-origin: center;
  @extend %labelregular;
  --labeloffset: 14px;
  --labelpad: 24px;

  .DynamicWaveLine {
    width: 100%;
  }

  &.top,
  &.bottom {
    width: calc(100% - (2 * var(--labelpad)) + 4px);
  }
  &.top {
    top: calc(var(--labeloffset));
    left: calc(var(--labelpad) - 2px);

    .DynamicWaveLine:last-child {
      transform: scaleX(-1);
    }
  }
  &.bottom {
    transform: rotate(180deg);
    bottom: calc(var(--labeloffset));
    left: calc(var(--labelpad) - 2px);

    .DynamicWaveLine:last-child {
      transform: scaleX(-1);
    }
  }
  &.left,
  &.right {
    flex-direction: column;
    .DynamicWaveLine {
      height: 100%;
      svg {
        position: absolute;
      }
    }
    .SubscribeDownload-label-text {
      transform-origin: center;
      flex-shrink: 0;
      width: 100%;
      padding: 1em 0;
      writing-mode: vertical-lr;
    }
  }
  &.left {
    left: var(--labeloffset);
    width: 1em;
    top: var(--labelpad);

    .DynamicWaveLine:last-child {
      transform: scaleY(-1);
    }
  }
  &.right {
    width: 1em;
    top: var(--labelpad);
    right: var(--labeloffset);

    .DynamicWaveLine:first-child {
      transform: scaleX(-1);
    }
    .DynamicWaveLine:last-child {
      transform: scaleX(-1) scaleY(-1);
    }
  }
}

.SubscribeDownload-label-text {
  margin: 0 1em;
}
