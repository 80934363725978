@import "~scss/imports";

.RecentNews {
  @extend %max;
  @extend %blockmarginvertical;
  display: grid;
  grid-gap: 1em;
  justify-items: start;
}

.RecentNews-label {
  @extend %sectionlabel;
}

.RecentNews-heading {
  @extend %h2blackcaps;
}

.RecentNews-items {
  margin-top: 1em;
  margin-bottom: 1em;
}

.RecentNews-link {
  justify-self: center;
}
