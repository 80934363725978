@import "mixins";
@import "type";
@import "spacing";

@mixin blockmarginifunwrapped {
  .ThemeWrapper & {
    margin-top: unset;
    margin-bottom: unset;
  }
  @extend %blockmarginvertical;
}

%imagegradient--right {
  position: relative;
  &:after {
    content: "";
    display: block;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.8),
      transparent
    );
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
}

%imagegradient--left {
  position: relative;
  &:after {
    content: "";
    display: block;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.8),
      transparent
    );
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

%line-list {
  $breakpoint: 1200px;
  --margin: 50px;
  display: grid;
  grid-gap: var(--margin);

  @media (min-width: $breakpoint) {
    grid-template-columns: repeat(auto-fit, 1fr);
    > * {
      grid-row: 1/-1;
    }
  }

  > * {
    position: relative;
  }
  > * + *:before {
    content: "";
    display: block;
    width: 100%;
    //border-top: 2px solid var(--teal2);
    position: absolute;
    top: calc(-1 * var(--margin) / 2);

    @media (min-width: $breakpoint) {
      width: 0;
      //border-top: 0;
      top: 0;

      height: 100%;
      border-left: 2px solid var(--teal2);

      position: absolute;
      left: calc(-1 * var(--margin) / 2);
    }
  }
}

%list-reset {
  list-style-type: none;
  padding: unset;
  margin: unset;
}

%sectionlabel {
  display: inline-flex;
  font-family: var(--mono);
  font-weight: 500;
  padding: 0.45rem 1.4rem;
  background-color: var(--teal2);
  text-transform: uppercase;
}

%button {
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  justify-self: start;
  justify-content: center;
  color: inherit;
  text-decoration: none;
  border: 0;
  box-shadow: inset 0 0 0 2px var(--teal2);
  padding: 1.05em 1.9em;
  width: 100%;

  @extend %drawborder;

  @media (min-width: 400px) {
    width: auto;
    min-width: 18em;
  }
}

%drawborder {
  position: relative;
  vertical-align: middle;
  transition: color 0.25s;

  &::before,
  &::after {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &::before,
  &::after {
    // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
    border: 2px solid transparent;
    width: 0;
    height: 0;
  }

  // This covers the top & right borders (expands right, then down)
  &::before {
    top: 0;
    left: 0;
  }

  // And this the bottom & left borders (expands left, then up)
  &::after {
    bottom: 0;
    right: 0;
  }

  // Hover styles
  &:hover::before,
  &:focus::before,
  &:hover::after,
  &:focus::after {
    width: 100%;
    height: 100%;
  }

  &:hover::before,
  &:focus::before {
    border-top-color: var(--blue4); // Make borders visible
    border-right-color: var(--blue4);
    transition: width 0.25s ease-out,
      // Width expands first
        height 0.25s ease-out 0.25s; // And then height
  }

  &:hover::after,
  &:focus::after {
    border-bottom-color: var(--blue4); // Make borders visible
    border-left-color: var(--blue4);
    transition: border-color 0s ease-out 0.5s,
      // Wait for ::before to finish before showing border
        width 0.25s ease-out 0.5s,
      // And then exanding width
        height 0.25s ease-out 0.75s; // And finally height
  }

  &:focus {
    outline: 0;
  }
}

%textinput {
  input {
    @extend %p;
    color: inherit;
    background-color: transparent;
    display: block;
    border: unset;
    border-radius: 0;
    border-bottom: 2px solid var(--teal2);
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;

    &::placeholder {
      color: inherit;
      opacity: 0.55;
    }

    &:focus {
      outline: 0;
      //border-bottom-color: var(--blue4) !important;
    }
  }
}

%wavegradientbg {
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:before {
    background-image: linear-gradient(to bottom, var(--red1) 41%, var(--blue1));
  }
  &:after {
    background-image: radial-gradient(
      circle at 50% 0,
      var(--teal1),
      var(--yellow1) 58%
    );
    clip-path: url(#bgclipwave);
  }
}
