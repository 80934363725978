@import "~scss/imports";

.HeadingStatementText {
  --pad: 40px;
  @extend %max;
  display: grid;
  grid-row-gap: 1em;
  padding-top: var(--pad);
  padding-bottom: var(--pad);

  grid-template-areas:
    "label"
    "heading"
    "statement"
    "text";

  max-width: 45em;
  margin-left: 0;

  @include desktop {
    grid-template-columns: 1fr 1fr;
    max-width: var(--max-desktop);
    margin-left: auto;

    grid-template-areas:
      "label ."
      "heading statement"
      "heading text";
    grid-column-gap: 5.7%;
  }
}

.HeadingOnly {
  --pad: 40px;
  @extend %max;
  display: grid;
  grid-row-gap: 1em;
  grid-template-areas: "label" "heading";
  padding-top: var(--pad);
  padding-bottom: calc(2*var(--pad));

  .HeadingStatementText-heading {
    max-width: 15em;
  }
}

.HeadingStatementText-label {
  grid-area: label;
  justify-self: start;
  @extend %sectionlabel;
}

.HeadingStatementText-heading {
  @extend %h1caps;
  grid-area: heading;
}

.HeadingStatementText-statement {
  @extend %subheading;
  grid-area: statement;

  @include desktop {
    margin-top: 3em;
  }
}

.HeadingStatementText-text {
  @extend %p;
  grid-area: text;
  margin-top: 1em;
}
