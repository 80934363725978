@import "~scss/imports";

.GridBlock {
  @extend %modulepadvertical;
}

.HeadingTextLink + .GridBlock {
  padding-top: 0;
  margin-top: calc(-1*var(--block-margin));
}

.GridBlock-list {
  @extend %maxmobile;
  list-style-type: none;
  padding-left: unset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include desktop {
    justify-content: space-between;
  }
}

.GridBlock-heading {
  @extend %p;
  @extend %maxmobile;

  @include desktop {
    padding-right: 40%;
  }
}

.GridBlockItem {
  width: 100%;

  @include tablet {
    width: 50%;
  }
  @include desktop {
    width: 25%;
  }
}

.GridBlock-list--2 .GridBlockItem {
  @include desktop {
    width: 50%;
  }
}


.GridBlock-list--3 .GridBlockItem {
  @include desktop {
    width: 33%;
  }
}


.GridBlockItem-inner {
  @extend %maxmobile;
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;

  .GridBlockItem {
    width: 100%;

    @include desktop {
      width: 33.33%;
    }
  }
}

.GridBlockItem {
  // @include desktop {
  //   padding-right: 3%;
  // }

  & + .GridBlockItem {
    margin-top: 2em;

    @include tablet {
      margin-top: unset;
    }
  }
}

.GridBlockItem-heading {
  @extend %h4;
  margin-top: 2em;
}

.GridBlockItem-subheading {
  @extend %bignumber;
  margin: 0.75em 0 0;
  width: 100%;
}

.GridBlockItem-subheading + .GridBlockItem-heading {
  color: var(--teal3);
  margin: 1rem 0 0;
}

.GridBlockItem-text {
  @extend %p;
  margin-top: 1em;
}
