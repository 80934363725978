@import "~scss/imports";

.Statistic {
  @extend %blockpaddingvertical;
}

.Statistic-wrapper {
  @extend %blockmarginvertical;
  @extend %max;
}

.Statistic-heading {
  @extend %h4black;
  @extend %max-shortp;
  color: var(--teal3);

  @include tablet {
    width: 66%;
  }

  @include desktop {
    width: 50%;
  }
}
.Statistic-copy {
  @extend %bignumberheading;
  margin: 1rem 0;
}
.Statistic-note {
  @extend %p;
  @extend %psmall;
}
