@import "~scss/imports";

.Impact-items {
  list-style-type: none;
  @extend %max;
  margin-top: 3em;

  display: grid;
  grid-gap: 3em;

  max-width: 30em;
  margin-left: 0;

  @media (min-width: 650px) {
    max-width: var(--max-desktop);
    margin-left: auto;
    grid-gap: 5vw;
    grid-template-columns: repeat(2, 1fr);
  }

  @include desktop {
    grid-template-columns: repeat(3, 1fr);

    .Impact-items-item-label {
      //min-height: 2.4em;
    }

    .Impact-items-item {
      grid-gap: 1em;
    }
    .Impact-items-item-explainer {
      margin-top: 0.5em;
    }
  }
}

.Impact-items-item {
  display: grid;
  grid-gap: 1em;
  grid-template-rows: auto auto 1fr;
}

.Impact-items-item-number {
  @extend %bignumber;
}
.Impact-items-item-label {
  @extend %bignumberlabel;
  color: var(--teal3);
}
.Impact-items-item-explainer {
  @extend %p;
  @extend %max-shortp;
}
