@import "~scss/imports";

.ProgramQuestions {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 2em;

  @include tablet {
    grid-gap: 0;
    grid-template-columns: 1fr 1fr;
  }
}

.ProgramQuestions-item {
  --pad: 3em;

  @include tablet {
    &:nth-child(odd) {
      padding-right: var(--pad);
    }
    &:nth-child(even) {
      padding-left: var(--pad);
      border-left: 2px solid var(--teal2);
    }
  }
}

.ProgramQuestions-item-heading {
  @extend %h4;

  @include tablet {
    min-height: 2.4em;
  }
}

.ProgramQuestions-item-text {
  @extend %p;
  margin-top: 1em;
}
