@import "~scss/imports";

.HeadingTextLink {
  //desktop

  display: grid;
  grid-template-areas: "label" "headingtext" "link";
  align-items: start;

  @extend %blockmarginvertical;
  @extend %max;
}

.HeadingTextLink .HeadingText {
  grid-area: headingtext;
}

.HeadingTextLink-label {
  @extend %h4;
  color: var(--teal3);
  grid-area: label;
  margin-bottom: 0.5rem;
}

.HeadingTextLink-text {
  grid-area: text;
}

.HeadingTextLink-link {
  margin-top: 1em;
}

.HeadingTextLink--right {
  @include desktop {
    grid-template-areas: "label label" "headingtext link";

    .HeadingTextLink-link {
      height: 100%;
      justify-self: self-end;
    }
  }
}

.HeadingTextLink--center .HeadingTextLink-link {
  justify-self: center;
}
