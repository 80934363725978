@import "~scss/imports";
.NewsList {
  @extend %list-reset;

  display: grid;
  grid-gap: 1em;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  // @media (min-width: 1200px) {
  //   grid-template-columns: repeat(4, 1fr);
  // }
}

.NewsList-item {
  width: 100%;
  background-color: var(--teal1);
  overflow: hidden;
  display: flex;

  a {
    color: inherit;
    text-decoration: unset;
    display: flex;
    flex-direction: column;

    &:hover {
      .NewsList-item-image {
        transform: translateY(10%) scale(1.1);
      }
    }
  }
}

.NewsList-item-title {
  @extend %news-card;
  padding: 1em;
  min-height: 5em;
  flex-shrink: 0;
  margin-bottom: 2em;
}

.NewsList-item-image {
  clip-path: url("#ImageWaveClip");
  object-fit: cover;
  object-position: center;
  height: 15em;
  margin-top: auto;
  transition: transform 0.3s;
}
