@import "~scss/imports";

.ServicesApply {
  @extend %max;
  @extend %blockmarginvertical;
}

.ServicesApply-iconthings {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 4em;

  display: grid;
  grid-gap: 1em;

  @include tablet {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4em;
  }
}

.ServicesApply-iconthings-item {
  display: grid;
  grid-template-areas:
    "icon heading"
    "icon text";
  grid-gap: 1em;
  grid-template-rows: auto 1fr;

  @include tablet {
    grid-template-areas:
      "icon"
      "heading"
      "text";
    grid-template-rows: auto auto 1fr;
    .ServicesApply-iconthings-item-heading {
      margin-top: 1em;
    }
  }
}
.ServicesApply-iconthings-item-icon {
  max-width: 80px;
  @include tablet {
    max-width: 140px;
  }
  grid-area: icon;
}
.ServicesApply-iconthings-item-heading {
  @extend %label;
  grid-area: heading;
}
.ServicesApply-iconthings-item-text {
  @extend %p;
  grid-area: text;
}

.ServicesApply-links {
  margin-top: 4em;
  display: grid;
  grid-gap: 1em;
}
