@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobileonly {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin forceverticalmargins {
  > div {
    padding-top: 1px;
    margin-top: -1px;
    // force margins to work inside??
  }
}
