@import "~scss/imports";

.PartnerList {
  --keycolorsize: 15px;
  @extend %max;
  @extend %blockmarginvertical;

  display: grid;
  grid-template-areas:
    "heading"
    "key"
    "list";
  grid-gap: 1em;

  @include tablet {
    grid-template-columns: 1fr 220px;
    grid-gap: 2em;
    align-items: start;
    grid-template-areas:
      "heading key"
      "list key";

    .PartnerList-list-heading {
      position: sticky;
      top: var(--nav-height);
    }
    .PartnerList-key {
      padding-top: 8em;
      position: sticky;
      top: var(--nav-height);
    }
  }
}

.PartnerList-key {
  list-style-type: none;
  margin: 0;
  padding: 0;

  grid-area: key;
}

.PartnerList-key-item {
  display: flex;
  align-items: center;

  @extend %buttonlabel;

  & + .PartnerList-key-item {
    margin-top: 1em;
  }
}

.PartnerList-key-item-color,
.PartnerListGroup-list-item-types-item-color {
  width: var(--keycolorsize);
  height: var(--keycolorsize);
  margin-right: calc(0.75 * var(--keycolorsize));
  flex-shrink: 0;
}

.PartnerList-heading {
  @extend %h2blackcaps;
  grid-area: heading;
  padding-bottom: 0.25em;
}

.PartnerList-list {
  grid-area: list;
  margin-top: 1em;
}

.PartnerListGroup + .PartnerListGroup {
  margin-top: 2em;
}

.PartnerListGroup-heading {
  @extend %h3;
  border-bottom: 2px solid var(--teal2);
}

.PartnerListGroup-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
  }
}

.PartnerListGroup-list-item {
  @include tablet {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  margin-top: 1em;
}
.PartnerListGroup-list-item-heading {
  @extend %p;
}

.PartnerListGroup-list-item-types {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;

  @include tablet {
    margin-left: 1em;
  }
}
