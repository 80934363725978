@import "~scss/imports";

.SimpleTextPage {
  @extend %blockmarginvertical;
  @extend %maxarticle;
}

.SimpleTextPage-heading {
  @extend %h2blackcaps;
}

.SimpleTextPage-body {
  @extend %p;
  @include padding;
}

.SimpleTextPage-links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 1em;

  a + a {
    margin-right: 0.5rem;
  }
}
