@import "~scss/imports";

.quoteContentBlock {

  @extend %blockmarginvertical;
  @extend %max;

  max-width: var(--max-desktop-small);
  margin-top: calc(var(--block-margin) / 2);

  background: url(~images/orangeWaves.svg);
  background-position: right center;
  background-size: auto;

  padding-left: 0px;
  padding-bottom: 32px;
}

.quoteContentBlock-wrapped {
  @extend %blockmarginvertical;
  @extend %max;

  max-width: var(--max-desktop-small);
  margin-top: calc(var(--block-margin) / 2);

  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  opacity: 1;
  background-color: rgba(229, 248, 255, 1);
  gap: 20px;
  align-items: center;
  padding-right: 0;
  margin-bottom: 0;
}

.quoteContentBlockTextQuote {
  opacity: 1;
  color: rgba(51, 53, 68, 1);
  font-family: "Roboto";
  font-size: 80px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0px;
  text-align: left;
  line-height: 80px;
}


.quoteContentBlockTextBold {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.3px;
  text-align: left;
  line-height: 40px;

  display: grid;
  grid-template-columns: 33px auto;
  grid-template-rows: auto;
  gap: 28px;
}

.quoteContentBlockTextMain {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 32px;
  padding-left: 58px;
}

.quoteContentBlockTextSign {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  text-align: left;
  line-height: 32px;
  text-transform: uppercase;
  padding-left: 58px;
  padding-top: 20px;

  display: grid;
  grid-template-columns: 33px auto;
  grid-template-rows: auto;
  gap: 28px;
  align-items: center;
}

.quoteContentBlockTextSignWave {
  width: 40px;
  vertical-align: center;
}
