@import "~scss/imports";

.VideoWaved-wave-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - var(--gutter));
  height: calc(100% - var(--gutter));
  z-index: -1;
  overflow: hidden;
}
.VideoWaved-wave-desktop {
  position: absolute;
  width: calc(100% - var(--gutter));
  height: calc(100% - var(--gutter));
  overflow: hidden;
  z-index: -1;
  top: 0;
  display: none;
}

.VideoWaved-inner {
  padding-top: var(--gutter);
  position: relative;
}
.VideoWaved-inner-inner {
  position: relative;

  display: grid;
  grid-template-areas:
    "image"
    "text";

  > * {
    position: relative;
  }
}

.VideoWaved-prevideo {
  grid-area: image;
  padding-left: var(--gutter);
  padding-top: var(--gutter);
  position: relative;
  z-index: 1;
}

.VideoWaved-text {
  grid-area: text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(2 * var(--gutter)) var(--gutter) calc(2 * var(--gutter));
  margin-top: calc(-1 * var(--gutter));
  background-color: var(--bg);
}

.VideoWaved-title {
  position: relative;

  span {
    @extend %h2blackcaps;
  }

  .WaveUnderline-wave {
    display: none;
  }
}

.VideoWaved-innertext {
  @extend %p;
  margin-top: 18px;
}

.VideoWaved-attribution {
  grid-area: attribution;
  margin-top: 1em;
}
.VideoWaved-link {
  grid-area: link;
}
.VideoWaved-video {
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  & > div {
    position: absolute;
    top: 0;
  }
}

.VideoWaved {
  --gutter: 16px;
  --bg: var(--blue1);
  --size-quoteimage: 24px;

  @extend %max;
  padding: 0;
  @extend %blockmarginvertical;
  position: relative;

  @media (min-width: 800px) {
    @include padding;

    --gutter: 30px;
    --size-quoteimage: 30px;

    .VideoWaved-video {
      padding-right: 30px;
      margin: 30px 0;
    }

    .VideoWaved-inner {
      padding-top: calc(var(--gutter) / 2);
      padding-right: var(--gutter);
    }

    .VideoWaved-inner-inner {
      background-color: var(--bg);
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      grid-template-areas: "text image";
      align-items: center;
      padding-right: 30px;
    }

    .VideoWaved-text {
      --pad: calc(2 * var(--gutter));
      background-color: var(--bg);
      padding: var(--gutter);
      margin-top: unset;
      height: 100%;
    }

    .VideoWaved-prevideo {
      padding-left: 0;
      padding-top: 0;
      max-width: 100%;
      margin-left: unset;
      margin-right: unset;
      left: 0;
    }

    .VideoWaved-wave-mobile {
      display: none;
    }

    .VideoWaved-wave-desktop {
      display: block;
      right: 0;
      left: auto;
    }
  }

  @media (min-width: 1100px) {
    .VideoWaved-video {
      min-width: 640px;
    }
  }
}
