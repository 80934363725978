@import "~scss/imports";

.Infographic {
  //@extend %blockmarginvertical;
  @extend %max;
  .Infographic-inner {
    position: relative;

    @media (min-width: 600px) {
      padding-left: 1.25em;
    }
    @include tablet {
      padding-left: 2.5em;
    }
  }
}

.Infographic-yAxis {
  @extend %pmono;
  font-size: 12px;

  @media (min-width: 600px) {
    transform: rotate(-90deg) translate(-10px, 50%);
    left: 0;
    position: absolute;
    transform-origin: left;
    bottom: 0;
  }

  @include tablet {
    font-size: 14px;
    transform: rotate(-90deg) translate(0, 100%);
  }

  @include desktop {
    transform: rotate(-90deg) translate(10px, 100%);
  }
}

// .HeadingTextLink + .Infographic {
//   @include desktop {
//     margin-left: auto;
//     margin-top: calc(-1 * var(--block-margin));
//   }
// }

.Infographic-xVals {
  @extend %pmono;
  color: var(--black);
  font-size: 14px;
}

.Infographic-yVals {
  @extend %pmono;
  color: var(--black);
  font-size: var(--size-campaignquote);
}
