@import "~scss/imports";
$breakpoint: 950px;

.CampaignCta {
  --pad: 16px;
  --imageoffset: calc(var(--pad) * 1.5);

  display: grid;
  grid-template-areas:
    "image"
    "copy";
  padding-top: var(--imageoffset);
  padding-bottom: 2em;
  position: relative;

  @include tablet {
    grid-template-areas: "image copy";
    grid-template-columns: repeat(2, 1fr);

    padding-top: 0;
    padding-bottom: 0;

    &.align-image--right {
      grid-template-areas: "copy image";
    }
    & + .CampaignCta {
      margin-top: 0;
    }
    .CampaignCta-copy {
      padding: 2em;
    }
  }
}

.CampaignCta-copy {
  grid-area: copy;
}

.CampaignCta-image, .CampaignCta-video {
  grid-area: image;

  @media (min-width: $breakpoint) {
    &.hidden-sm {
      display: none;
    }
  }
}

.CampaignCta-video {
  display: none;
  overflow: hidden;

  .play {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0, .3) url(~images/play_icon.svg) no-repeat center;
    cursor: pointer;
  }

  &:hover {
    opacity: .95;
  }

  @media (min-width: $breakpoint) {
    display: block;

    video {
      display: block;
    }
  }
}

.CampaignCta-text {
  @extend %plarge;
  margin-top: 1em;
}

.CampaignCta-link {
  margin-top: 2em;
}

.CampaignCta--default {
  // & + .CampaignCta {
  //   margin-top: 5em;
  // }
  .CampaignCta-heading {
    @extend %h2blackcaps;
  }

  .CampaignCta-bg {
    position: absolute;
    top: var(--imageoffset);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .CampaignCta-image, .CampaignCta-video {
    align-self: center;
    width: calc(100% - (2 * var(--pad)));
    margin: 0 auto;
    position: relative;
    margin-top: calc(-1 * var(--imageoffset));
  }

  .CampaignCta-copy {
    display: flex;
    align-items: center;
    padding-bottom: 4em;
  }
  .CampaignCta-copy-inner {
    max-width: 30em;
    padding: 0 1em;
    margin-top: 1em;
  }

  @include tablet {
    .CampaignCta-image, .CampaignCta-video {
      width: 100%;
      margin-top: 0;
    }
    .CampaignCta-copy {
      padding-bottom: 2em;
    }
    .CampaignCta-copy-inner {
      margin: auto;
    }
  }
}

.CampaignCtaFeatured {
  @extend %blockmarginvertical;
  display: grid;
  grid-gap: 1em;

  grid-template-areas:
    "image"
    "heading"
    "text"
    "link";
  align-items: center;

  .CampaignCta-heading,
  .CampaignCta-text,
  .CampaignCta-link {
    @include padding;
    max-width: 30em;
  }

  @include tablet {
    grid-template-areas:
      "image heading"
      "text text"
      "link link";
    grid-template-columns: 1fr 1fr;
  }

  @include desktop {
    grid-template-areas:
      "image ."
      "image heading"
      "image text"
      "image link"
      "image .";
    grid-template-rows: 1fr auto auto auto 1fr;
  }

  .CampaignCta-text {
    grid-area: text;

    // @include tablet {
    //   padding: 0 4vw;
    // }
  }

  .CampaignCta-heading {
    color: var(--blue3);
    @extend %h1caps;
    max-width: 12em;
    grid-area: heading;
  }
  .CampaignCta-link {
    grid-area: link;
  }
}
