@import "~scss/imports";

.Pullquote {
  margin: 36px 0;
}
.Pullquote-statement {
  @extend %h2;
  color: var(--teal3);
}
.Pullquote-attribution {
  @extend %p;
  margin-top: 0.5em;
}
