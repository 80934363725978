@import "~scss/imports";
$breakpoint: 1000px;

.Footer {
  min-height: 300px; //todo remove

  background-color: var(--teal4);
  color: var(--white);
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.Footer-inner {
  @extend %max;

  @media (min-width: $breakpoint) {
    display: flex;
    justify-content: space-between;
  }
}
.Footer-right {
  display: grid;
  grid-gap: 2em;
  align-items: end;
  margin-top: 4em;
  @media (min-width: $breakpoint) {
    grid-gap: 1em;
    margin-top: 0;
    margin-left: 4em;
  }
}

.SocialLinks,
.FooterLinks {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 2em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  @media (min-width: $breakpoint) {
    justify-content: flex-end;
    li {
      margin-right: 0;
      margin-left: 2em;
    }
  }

  > li {
    grid-row: 1;
  }

  > li > a {
    display: block;
    color: inherit;
    text-decoration: none;
    &:hover {
      color: var(--teal2);
    }
  }
}

.SocialLinks {
  li img {
    height: 32px;
    width: auto;
  }
  li {
    margin-right: 1.5em;
  }
  @media (min-width: $breakpoint) {
    li {
      margin-left: 3em;
      margin-right: unset;
    }
  }
  a:focus {
    outline: 2px solid var(--teal2);
  }
}

.FooterLinks--primary {
  li {
    margin-right: 2em;
    @media (min-width: $breakpoint) {
      margin-right: 0;
      margin-left: 3em;
    }
  }

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-transform: uppercase;
}

.FooterLinks--secondary {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
}
