.VideoEmbed {
  position: relative;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.VideoEmbed-wrapper {
  height: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    .VideoEmbed-playbutton {
      transform: scale(1.04);
    }
    .VideoEmbed-playbutton img:first-child {
      transform-origin: center;
      animation: spin 50s linear infinite;
    }
  }

  .VideoEmbed-playbutton {
    --size: 150px;
    transition: transform 0.3s;

    position: absolute;
    top: calc(50% - var(--size) / 2);

    width: var(--size);
    height: var(--size);
    pointer-events: none;

    img {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;

      &.triangle {
        margin-left: -4px;
      }
    }
  }

  iframe {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
}

.VideoEmbed-placeholder {
  background-color: purple;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
