@import "~scss/imports";

.ArrowBackButton {
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  @extend %ctalink;

  img {
    margin-right: 0.5em;
    width: 23px;
  }
}
