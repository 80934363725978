@import "~scss/imports";

.MoreNews {
  @extend %max;
  margin-top: 6em;
  margin-bottom: 6em;
}

.MoreNews-heading {
  @extend %h2;
}

.MoreNews-more {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}
