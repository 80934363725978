// .Loading {
//   display: flex;
//   justify-content: center;
//   padding: 1em;
//   animation: pulse 1s infinite alternate;

//   img {
//     display: block;
//     width: 50px;
//     height: auto;
//   }
// }
@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}

.Loading {
  @media all and (-ms-high-contrast: none) {
    margin-top: calc(50vh - 80px);
  }
}
