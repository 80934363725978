@import "~scss/imports";

.helpfulResourcesContentBlock {
  @extend %max;
  @extend %blockpaddingvertical;
}

.helpfulResourcesContentBlockHeader {
  text-align: center;
  margin-bottom: 70px;
}

.helpfulResourcesContentBlockList {
}

.helpfulResourcesContentBlockHeader h2 {
  position: relative;
  display: inline-block;

  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 900;
  font-style: Black;
  letter-spacing: 0.4px;
  line-height: 56px;
  text-transform: uppercase;
}

.helpfulResourcesContentBlockHeader h2:after {
  content: '';
  position: absolute;
  bottom: -6.83px;
  left: 0;
  width: 100%;
  height: 6.83px;
  background-image: url("./wave.svg");
}


.HelpfulResourcesContentBlockItem {
  width: 392px;
  height: 128px;

  border: 1px solid rgba(206, 206, 206, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);

  padding-left: 32px;
  padding-right: 38px;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  gap: 20px;

  align-items: center;
}

.HelpfulResourcesContentBlockItemLink {
  display: grid;
  grid-template-columns: auto 30px;
  grid-template-rows: auto;
  gap: 20px;
}

.HelpfulResourcesContentBlockItemLink span {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 27px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.3px;
  text-align: left;
  line-height: 40px;
}

.HelpfulResourcesContentBlockItemLink svg {
  align-self: center;
}
