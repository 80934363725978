@import "~scss/imports";

.securityHighlightContentBlock {
  background-color: #003046;
  color: white;
}

.securityHighlightContentBlock-Wrapped {
  //desktop

  @extend %max;
  @extend %blockpaddingvertical;

  max-width: var(--max-desktop-small);

  display: grid;
  grid-template-columns: 1fr 60px auto;
  grid-template-rows: auto;
  gap: 20px;

  padding-left: 35px;
  padding-right: 35px;
}

.securityHighlightContentBlock-Icon {
  margin-top: 33px;
}

.securityHighlightContentBlock-Header {
  position: relative;
  display: inline-block;

  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 900;
  font-style: Black;
  letter-spacing: 0.4px;
  text-align: left;
  line-height: 56px;
  text-transform: uppercase;
}

.securityHighlightContentBlock-Header:after {
  content: '';
  position: absolute;
  bottom: -6.83px;
  left: 0;
  width: 100%;
  height: 6.83px;
  background-image: url("./wave.svg");
}

.securityHighlightContentBlock-Text {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.4px;
  text-align: left;
  line-height: 28px;
}

.securityHighlightContentBlock-SubText {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
}

.securityHighlightContentBlock-SubText a
{
  color: inherit;
}