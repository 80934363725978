@import "~scss/imports";

.InternationalLinks {
  @extend %max;
  @extend %blockmarginvertical;
  display: grid;
  grid-gap: 2em;
  @extend %buttonlabel;

  @media (min-width: 400px) {
    justify-items: center;
  }
}

.InternationalLinks-item-heading {
  margin-bottom: 0.5em;
}
