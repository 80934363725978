@import "~scss/imports";

.ImageLink-wrapper {
  @extend %max;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: var(--block-margin);
  padding-top: 1rem;

  @include tablet {
    padding-top: 2rem;
  }

  .HeadingText {
    width: 100%;
  }
}

.ImageLink {
  width: 100%;

  .ArrowLinkBlock {
    padding: 0;
  }

  @include tablet {
    width: calc(50% - 30px);
  }

  @include desktop {
    width: calc(50% - 60px);
  }
}

.ImageLink-heading {
  @extend %h4black;
}
