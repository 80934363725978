@import "~scss/imports";

.NationHeader {
  background-image: linear-gradient(
    to bottom,
    rgba(215, 249, 244, 0.5),
    #ffffff
  );
}

.NationHeader-inner {
  display: grid;
  margin-top: 4em;

  grid-template-areas:
    "image1"
    "label"
    "heading"
    "statement"
    "link"
    "subheading"
    "subtext"
    "attributes"
    "image2"
    "values";
  grid-gap: 1em;
  --gap: 1rem;

  .NationHeader-label,
  .NationHeader-heading,
  .NationHeader-statement,
  .NationHeader-link,
  .NationHeader-subheading,
  .NationHeader-subtext,
  .NationHeader-attributes {
    padding: 0 var(--gap);
  }

  @include tablet {
    --gap: 4vw;
    grid-template-areas:
      "image1 label label"
      "image1 heading heading"
      "image1 statement statement"
      "image1 link link"
      "subheading subheading image2"
      "subtext subtext image2"
      "attributes attributes image2"
      "values values values";
    grid-template-columns: 2fr 1fr 50%;
    grid-template-rows: repeat(7, auto) 1fr;

    .NationHeader-subheading,
    .NationHeader-subtext,
    .NationHeader-attributes {
      padding: 0 calc(var(--gap) * 2);
    }
  }
}

.NationHeader-label {
  grid-area: label;
  justify-self: flex-start;
  display: flex;
  flex-direction: column;
  @include tablet {
    flex-direction: row;
  }
}
.NationHeader-label-text {
  @extend %sectionlabel;
  display: flex;
  align-items: center;
  background-color: var(--teal2);
}
.NationHeader-image1 {
  grid-area: image1;
}

.NationHeader-heading {
  grid-area: heading;
  @extend %h1caps;
  max-width: 15em;
}

.NationHeader-statement {
  grid-area: statement;
  @extend %p;
  @extend %max-explainer;
}
.NationHeader-link {
  grid-area: link;
  margin-top: 1em;
  @extend %max-explainer;
}

.NationHeader-subheading {
  grid-area: subheading;
  @extend %h2blackcaps;
  margin-top: 2em;
}
.NationHeader-subtext {
  grid-area: subtext;
  @extend %p;
  margin-top: 1em;
}
.NationHeader-attributes {
  grid-area: attributes;
  margin-top: 2em;
}
.NationHeader-image2 {
  grid-area: image2;
  margin-top: 3em;
}

.NationHeader-values {
  grid-area: values;
  background-color: var(--blue2);
  color: var(--white);
  margin-top: 4em;
}

.LanguageSelector {
  position: relative;
}
.LanguageSelector-toggle {
  border: 2px solid var(--teal2);
  font-size: inherit;
  @extend %sectionlabel;
  background-color: transparent;
  display: flex;
  align-items: center;

  svg {
    margin-left: 1em;
  }
}

.LanguageSelector-dropdown {
  @extend %list-reset;
  font-weight: 500;
  position: absolute;
  top: 100%;
  width: 100%;
  border: 2px solid var(--teal2);
  border-top-width: 0;
  li {
    @extend %sectionlabel;
    width: 100%;
    background-color: transparent;

    a {
      text-decoration: none;
      color: inherit;
      display: block;
      width: 100%;
    }
  }
}
