@import "~scss/imports";
$breakpoint: 900px;

.CtaItems {
  display: grid;
  grid-gap: 2em;
  @extend %max;

  @media (min-width: $breakpoint) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4em;
  }
}

.CtaItems-item {
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    "image"
    "heading"
    "text"
    "link";
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr auto;

  @media (min-width: 500px) {
    grid-template-areas:
      "image heading"
      "image text"
      "image link";
    grid-template-columns: auto 1fr;
  }
  @media (min-width: $breakpoint) {
    grid-template-areas:
      "image"
      "heading"
      "text"
      "link";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
  }
}

.CtaItems-item-image {
  max-width: 140px;
  grid-area: image;
}
.CtaItems-item-heading {
  @extend %h4;
  grid-area: heading;
}

.CtaItems-item-text {
  @extend %p;
  grid-area: text;
}

.CtaItems-item-link {
  grid-area: link;
  align-self: end;
  font-weight: var(--weight-bold);
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 28px;
  // border: 2px solid #003046;

  color: inherit;
  text-decoration: none;

  display: block;
}
