@import "~scss/imports";

.ProgramAttributeList {
  --margin: 32px;
  padding-left: 50px;
  grid-area: attributes;
  margin: unset;
  // margin-bottom: var(--margin);
}

.ProgramAttributeList-item {
  & + .ProgramAttributeList-item {
    margin-top: var(--margin);
  }
}

.ProgramAttributeList-item-heading {
  @extend %plargebold;
}

.ProgramAttributeList-item-more {
}
