@import "~scss/imports";

.Impact {
  @extend %max;
  @extend %blockmarginvertical;

  .Impact-items {
    padding-left: 0;
    padding-right: 0;
  }
}

.Impact-link {
  margin-top: 3em;
}
