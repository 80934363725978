@import "~scss/imports";

.ProgramDifferences {
  --padding: 60px;
  @extend %maxmobile;
  @extend %modulepadvertical;
  display: flex;
  flex-direction: column;

  @include desktop {
    align-items: center;
  }
}

.ProgramDifferences-list {
  list-style-type: none;
  padding: unset;
  margin: unset;

  .ProgramDifferencesItem {
    width: 100%;

    & + .ProgramDifferencesItem {
      margin-top: 2em;
    }
  }

  @include desktop {
    display: flex;
    flex-wrap: wrap;

    .ProgramDifferencesItem {
      width: 50%;
      border-left-color: var(--teal2);
      & + .ProgramDifferencesItem {
        margin-top: 0;
      }
    }
    .ProgramDifferencesItem:nth-child(odd) {
      padding-right: 2em;
    }
    .ProgramDifferencesItem:nth-child(even) {
      border-left-width: 2px;
      border-left-style: solid;
      padding-left: 2em;
    }
  }
}

.ProgramDifferences-link {
  margin-top: 2em;
}
.ProgramDifferencesItem-heading {
  @extend %h3caps;
}

.ProgramDifferencesItem-text {
  @extend %p;
  margin-top: 1em;
}
