@import "~scss/imports";

@mixin radioset {
  .RadioSet-inner {
    display: flex;
  }
  .Radio {
    display: flex;
    align-items: center;
  }
  .Radio input {
    position: absolute;
    opacity: 0;

    & + .Radio-indicator {
      display: block;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      border: 2px solid;
      margin-right: 0.5em;
    }
    &:focus + .Radio-label {
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}

.Subscribe {
  max-width: var(--max-subscribe-width);

  .Input {
    display: block;
    margin-top: 3.5%;
    @extend %textinput;
  }
  .InputDisclaimer {
    margin-top: 0.5em;
  }

  .RadioSet {
    border: 0;
    margin: 0;
    padding: 0;
    @extend %p;

    legend {
      padding: 0;
      margin-right: 1em;
    }

    .Radio input:focus + .Radio-indicator {
      border-color: var(--blue4) !important;
    }

    @include radioset;
  }
}

.Subscribe-age {
  grid-area: age;
}
.Subscribe-input {
  grid-area: input;
}
.Subscribe-submit {
  grid-area: submit;
  @extend %button;
  @extend %buttonlabel;
  width: 100%;
  margin-top: 1.5em;
}

.SubscribeSuccess {
  @extend %formsuccess;
  align-items: center;
  display: flex;

  img {
    width: 25px;
    margin-right: 0.5em;
  }
}

.Subscribe--horizontal {
  display: grid;
  grid-template-areas: "age input submit";
  grid-template-columns: auto auto auto;
  grid-gap: 2rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  .Input,
  .Subscribe-submit {
    margin-top: 0;
  }
  .Subscribe-submit {
    width: auto;
    min-width: auto;
    align-self: start;
  }
}
