@import "~scss/imports";

.ourPrivacyApproachContentBlock {
  //desktop

  @extend %blockpaddingvertical;
  @extend %max;

  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
  gap: 44px;
}

.ourPrivacyApproachContentBlockHeader {
  position: relative;
  display: inline-block;

  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 900;
  font-style: Black;
  letter-spacing: 0.4px;
  text-align: left;
  line-height: 56px;
  text-transform: uppercase;

  margin-top: 0;
}

.ourPrivacyApproachContentBlockHeader:after {
  content: '';
  position: absolute;
  bottom: -6.83px;
  left: 0;
  width: 80%;
  height: 6.83px;
  background-image: url("./wave.svg");
}

@media (max-width: 1100px) {
  .ourPrivacyApproachContentBlockHeader:after {
    width: 100%;
  }
}

.ourPrivacyApproachContentBlockItem {
  display: grid;
  grid-template-columns: 72px auto;
  grid-template-rows: auto;
  gap: 20px;
}

.ourPrivacyApproachContentBlockItem-Icon {
  width: 72px;
  height: 72px;
}


.ourPrivacyApproachContentBlockItem-Header {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 700;
  font-style: Bold;
  letter-spacing: 0.3px;
  text-align: left;
  line-height: 40px;
}

.ourPrivacyApproachContentBlockItem-Text {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 32px;
}
