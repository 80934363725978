@import "mixins";

@mixin fluid-property($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(
        #{$min-value} +
          #{strip-unit($max-value - $min-value)} *
          (100vw - #{$min-vw}) /
          #{strip-unit($max-vw - $min-vw)}
      );
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

$fluidmin: 320px;
$fluidmax: 1440px;

$navBreakpoint: 1100px;

@mixin fluid($properties, $min-value, $max-value) {
  @include fluid-property(
    $properties,
    $fluidmin,
    $fluidmax,
    $min-value,
    $max-value
  );
}

//text sizes
:root {
  //@include fluid(--size-h1, 42px, 60px);
  --size-h1: 42px;
  //@include fluid(--size-h2, 36px, 42px);
  --size-h2: 36px;
  //@include fluid(--size-h3, 16px, 30px);
  --size-h3: 1em;
  --weight-h3: 500;
  //@include fluid(--size-p, 16px, 20px);
  --size-p: 1em; //16px;
  //@include fluid(--size-plarge, 20px, 24px);
  --size-plarge: 1.25em; //20px;
  //@include fluid(--size-bigquote, 36px, 60px);
  --size-bigquote: 2.25em; //36px;
  //@include fluid(--size-bignumber, 46px, 78px);
  --size-bignumber: 46px;
  //@include fluid(--size-bignumberexplainer, 16px, 24px);
  --size-bignumberexplainer: 1em;
  //@include fluid(--size-bignumberheader, 46px, 78px);
  --size-bignumberheader: 46px;
  //@include fluid(--size-campaignquote, 24px, 42px);
  --size-campaignquote: 24px;
  //@include fluid(--size-subheading, 16px, 30px);
  --size-subheading: 1em;

  --size-imagewaveheading: 36px;
  --size-imagerotateheading: 24px;

  --size-programheading: 36px;
  --size-alertbartext: 12px;
  --size-formsuccess: 20px;
  --size-maptooltip: 16px;

  @media (min-width: 400px) {
    --size-alertbartext: 14px;
    --size-h1: 42px;
  }

  @include tablet {
    --size-h1: 3em; //60px;
    --size-h2: 2.625em; //42px;
    --size-h3: 1.875em; //30px;
    --weight-h3: 900;
    --size-p: 1.25em; //20px;
    --size-plarge: 1.5em; // 24px;
    --size-bigquote: 2.75em;
    --size-bignumber: 4.875em; //78px;
    --size-bignumberexplainer: 1.5em; // 24px;
    --size-bignumberheader: 4.875em; //78px;
    --size-campaignquote: 2.625em; //42px;
    --size-programheading: 30px;

    --size-imagewaveheading: 5vw;
  }

  @include desktop {
    --size-h1: 3.75em; //60px;
    --size-imagewaveheading: 4vw;
    --size-imagerotateheading: 4vw;
    --size-subheading: 1.875em; //30px;
  }
}

//spacing
:root {
  --max-desktop: 1176px;
  --max-desktop-small: 1000px;
  --max-mobile: 700px;

  --block-margin: 60px;
  --module-padding: 60px;

  --nav-height: 79px;

  --max-shortp: 600px;

  --max-explainer: 40rem;
  --max-subscribe-width: 360px;

  @include tablet {
    --nav-height: 100px;

    --block-margin: 100px;
    --module-padding: 100px;
  }
}
//font
:root {
  --roboto: "Roboto", sans-serif;
  --mono: "Roboto Mono", monospace;

  --weight-black: 900;
  --weight-bold: 700;
}

//color
:root {
  --black: #003046;
  --green: #0d9c90;
  --lightgreen: #d7f9f4;

  --white: #ffffff;

  --black1: #ededed; /*--very-light-pink*/
  --black2: #cecece; /*--very-light-pink*/
  --black3: #7a7a78; /* medium-grey */
  --black4: #1a1918; /* black */

  --teal1: #d7f9f4; /* ice */
  --teal2: #43d6b9; /* aqua marine */
  --teal3: #0d9c90; /* blue-green */
  --teal4: #0c6b68; /* blue-green */

  --blue1: #e5f8ff; /* ice-blue */
  --blue2: #0169e1; /* cerulean-blue */
  --blue3: #0d38d3; /* blue */
  --blue4: #003046; /* dark-teal */

  --red1: #feefee; /* very light pink */
  --red2: #f37c6f; /* salmon */
  --red3: #ea3850; /* red-pink */
  --red4: #a31746; /* rouge */

  --orange1: #fff3e8; /* very light pink */
  --orange2: #f9b88c; /* peach */
  --orange3: #fa7815; /*orange */

  --yellow1: #fff9e2; /* off-white */
  --yellow2: #fce688; /* wheat */
  --yellow3: #fdd946; /* maize */
}
