@import "~scss/imports";

.ConicGradientBackground {
  background-image: linear-gradient(
    to right,
    #d7f9f4,
    #fff9e2,
    #fff3e8,
    #e5f8ff,
    #d7f9f4
  );
  background-image: conic-gradient(
    from 45deg,
    #d7f9f4,
    #fff9e2,
    #fff3e8,
    #e5f8ff,
    #d7f9f4
  );
  background-size: cover;
  padding-bottom: var(--block-margin);
}
