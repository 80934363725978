@import "~scss/imports";

.HeadingText {
  @extend %max-explainer;
}

.HeadingText-heading {
  @extend %h2blackcaps;
}
.HeadingText-text {
  @extend %plarge;
  margin-top: 1em;
}
