@import "~scss/imports";

.dataPrivacyAddendumSection {
  padding-top: var(--block-margin);
  @extend %max;
}

.dataPrivacyAddendumSection-wrapper {
  background-color: var(--blue1);
  text-align: center;
  padding-bottom: var(--block-margin);
}

.dataPrivacyAddendumSectionHeader {
  text-align: center;
}

.dataPrivacyAddendumSectionHeader h2 {
  position: relative;
  display: inline-block;

  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 900;
  font-style: Black;
  letter-spacing: 0.4px;
  text-align: left;
  line-height: 56px;
  text-transform: uppercase;
}

.dataPrivacyAddendumSectionHeader h2:after {
  content: '';
  position: absolute;
  bottom: -6.83px;
  left: 0;
  width: 100%;
  height: 6.83px;
  background-image: url("./wave.svg");
}

.dataPrivacyAddendumSectionContent {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 28px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0.3px;
  text-align: left;
  line-height: 40px;

  display: inline-block;
  max-width: 800px;
}

.dataPrivacyAddendumSectionSelect {
  text-align: center;
}

.dataPrivacyAddendumSection-wrapper .AutocompleteSelect {
  display: inline-block;
  width: 430px;
}

.dataPrivacyAddendumSection-wrapper .AutocompleteSelect input {
  color: inherit;
  background-color: transparent;
  display: block;
  border: unset;
  border-radius: 0;
  border-bottom: 2px solid var(--black);
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
}

.dataPrivacyAddendumSection-wrapper .AutocompleteSelect input::placeholder {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 32px;
}

.dataPrivacyAddendumSection-wrapper .AutocompleteSelect input:focus {
  outline: 0;
}

.dataPrivacyAddendumSection-wrapper .AutocompleteSelect-input svg path {
  fill: var(--black);
}

.AutocompleteSelect {
  position: relative;
  @extend %textinput;
  max-width: 430px;
}

.AutocompleteSelect-results {
  box-sizing: content-box;
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  left: -1px;
  z-index: 999;

  box-shadow: 0px 2px 4px rgba(1, 48, 70, 0.4);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.AutocompleteSelect-input {
  svg {
    float: right;
    margin-top: -28px;
  }
}

.AutocompleteSelect-loading {
  position: absolute;
  right: 0;
  top: 0.5em;
  display: flex;
  align-items: center;
  font-size: 2em;
  z-index: 2;
}

.AutocompleteSelect-results-dropdown {
  padding: 15px;
  width: 100%;
}

.AutocompleteSelect-results-dropdown-item {
  padding: 0.5em;
}

.AutocompleteSelect-results-dropdown-item-main,
.AutocompleteSelect-results-dropdown-item-main a{
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 32px;
  text-decoration: none;

  cursor: pointer;
}

.DataPrivacyAddendumSectionResult {
  text-align: left;
  margin-top: 64px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;

  display: inline-block;
  max-width: 800px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);

  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 32px;
}

.DataPrivacyAddendumSectionNegativeResultButton {
  border: 2px solid rgba(0, 222, 183, 1);
  opacity: 1;

  display: flex;
  align-items: center;
  text-decoration: none;

  max-width: 190px;
}

.DataPrivacyAddendumSectionNegativeResultButton svg {
  margin-right: 6px;
}

.DataPrivacyAddendumSectionResult h3 {
  margin-top: 0px;
}

.DataPrivacyAddendumSectionPositiveResultContent {
  opacity: 1;
  color: rgba(0, 48, 70, 1);
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0.3px;
  text-align: left;
  line-height: 40px;
}

.DataPrivacyAddendumSectionNegativeResultDownloadContent
{
  margin-bottom: 30px;
}