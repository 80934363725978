@import "~scss/imports";

.NewsStreamHeader {
}

.NewsStreamHeader-heading {
  @extend %h2blackcaps;
  margin-bottom: 1em;
}

.NewsStreamHeader-label {
  @extend %sectionlabel;
  margin-bottom: 1em;
}
