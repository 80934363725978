@import "~scss/imports";

.ProgramApplicationClub-top {
  display: grid;
  grid-gap: 1em;
  grid-template-areas:
    "heading"
    "text"
    "subscribe";
  @include tablet {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "heading ."
      "text subscribe";
    grid-column-gap: 4em;
    grid-row-gap: 2em;
  }
}
.ProgramApplicationClub-heading {
  @extend %h2blackcaps;
  grid-area: heading;
}

.ProgramApplicationClub-text {
  @extend %plarge;
  grid-area: text;
}
.ProgramApplicationClub-subscribe {
  grid-area: subscribe;
}
.ProgramApplicationClub-details {
  margin-top: 3em;
}
.ProgramApplicationClub-details-heading {
  @extend %h3caps;
}

.ProgramApplicationClub-details-text {
  @extend %p;
  margin-top: 1em;
}

.ProgramApplicationClub-details-attributes {
  margin-top: 3em;

  @include desktop {
    column-count: 2;
    column-gap: 6em;
  }
}
