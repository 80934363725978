@import "~scss/imports";

@mixin filter--right {
  img {
    -webkit-mask: linear-gradient(to right, black 70%, transparent 100%);
  }
}
@mixin filter--top {
  img {
    -webkit-mask: linear-gradient(to top, black 75%, transparent 100%);
  }
}
@mixin filter--left {
  img {
    -webkit-mask: linear-gradient(to left, black 80%, transparent 100%);
  }
}

.ImageWaveText {
  display: grid;
  grid-template-areas:
    "text"
    "image2"
    "image1"
    "image3";
  @extend %blockmarginvertical;
  .ImageWaveText-text > div {
    text-shadow: 0 0 0.25em white;
  }

  @include tablet {
    grid-template-areas:
      "image1 text image3"
      ". image2 .";
    grid-template-columns: 6fr 6fr 3fr;
    grid-template-rows: 30vh 1fr;

    .ImageWaveText-text > div {
      width: 150%;
      margin-left: -20%;
      margin-top: 15%;
    }

    .ImageWaveText-image1 {
      margin-top: 0;
      width: 100%;
      @include filter--right;
    }
    .ImageWaveText-image2 {
      margin-top: 0;
      padding-right: 0;
      width: 80%;
      margin-left: 15%;
      margin-right: 10%;
      @include filter--top;
    }
    .ImageWaveText-image3 {
      display: block;
      margin-top: 0;
      width: 100%;
      justify-self: start;
      @include filter--left;
    }
  }

  @include desktop {
    grid-template-columns: 4fr 4fr 2fr;
    grid-template-rows: 35vh 1fr;

    .ImageWaveText-text > div {
      margin-top: 20%;
    }
  }
}

// .ImageWaveText-image {
//   grid-area: image;
//   width: 150%;
//   @extend %imagegradient--right;
// }

.ImageWaveText-image1 {
  grid-area: image1;
  width: 66%;
  margin-top: -20%;
  z-index: 2;
}

.ImageWaveText-image2 {
  grid-area: image2;
  width: 50%;
  margin-left: auto;
  margin-top: -5%;
  z-index: 3;
  padding-right: 1rem;
  @include filter--top;
}
.ImageWaveText-image3 {
  display: none;
  grid-area: image3;
  width: 66%;
  margin-left: auto;
  margin-top: -5%;
  z-index: 1;
}
.ImageWaveText-text {
  align-items: center;
  display: flex;
  grid-area: text;
  @extend %imagewaveheading;
  padding: 0 1rem;
  max-width: 15em;

  position: relative;
  z-index: 4;
}
