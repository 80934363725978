@import "~scss/imports";

.Attribution {
  @extend %pcaps;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.5rem;
  }
}
