%h1 {
  font-size: var(--size-h1);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.63px;
}
%h1caps {
  @extend %h1;
  text-transform: uppercase;
}

%h2 {
  font-size: var(--size-h2);
  line-height: 1;
}
%h2black {
  @extend %h2;
  font-weight: 900;
}
%h2blackcaps {
  @extend %h2black;
  text-transform: uppercase;
}
%h2caps {
  @extend %h2;
  text-transform: uppercase;
}

%subheading {
  font-size: var(--size-subheading);
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: -0.36px;
  text-transform: uppercase;
  @include desktop {
    font-weight: 900;
  }
}
%h3 {
  font-size: var(--size-h3);
  font-weight: var(--weight-h3);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}

%h3caps {
  @extend %h3;
  text-transform: uppercase;
}

%h4 {
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  text-transform: uppercase;
}

%h4black {
  @extend %h4;
  font-weight: 900;
}

%p {
  font-size: var(--size-p);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}
%pmono {
  @extend %p;
  font-family: var(--mono);
}
%pcaps {
  @extend %p;
  text-transform: uppercase;
}
%pbold {
  @extend %p;
  font-weight: bold;
}
%plarge {
  font-size: var(--size-plarge);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}
%plargebold {
  @extend %plarge;
  font-weight: bold;
}
%psmall {
  font-size: 16px;
}

%plargebold {
  @extend %plarge;
  font-weight: bold;
}

%bigquote {
  font-size: var(--size-bigquote);
  //text-transform: uppercase;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.63px;
}
%campaignquote {
  font-size: var(--size-campaignquote);
}

%label {
  font-weight: bold;
  font-family: var(--mono);
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-transform: uppercase;
}
%labelregular {
  font-family: var(--mono);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
}

%reset-link {
  color: inherit;
  text-decoration: unset;
}

%navitem {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}

%navitem-mobile {
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

%bignumber {
  font-family: var(--mono);
  font-size: var(--size-bignumber);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
}

%bignumberlabel {
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  text-transform: uppercase;
}

%bignumberexplainer {
  font-size: var(--size-bignumberexplainer);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

%bignumberheading {
  font-family: var(--mono);
  font-size: var(--size-bignumberheader);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: normal;
}

%inputdisclaimer {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
}

%imagewaveheading {
  font-size: var(--size-imagewaveheading);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.63px;
  text-transform: uppercase;
}

%imagerotateheading {
  font-size: var(--size-imagerotateheading);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.63px;
  text-transform: uppercase;
}

%programheading {
  font-size: var(--size-programheading);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.36px;
  text-transform: uppercase;
}

%alertbartext {
  font-family: var(--mono);
  font-size: var(--size-alertbartext);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
}

%formsuccess {
  font-size: var(--size-formsuccess);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}

%formlabel {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-transform: uppercase;
}
%formlabel-mono {
  @extend %formlabel;
  font-family: var(--mono);
}

%ctalink {
  font-size: 20px;
  color: var(--teal3);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.5px;
}

%maptooltip {
  font-size: var(--size-tooltiptext);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

%buttonlabel {
  font-family: var(--sans);
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-transform: uppercase;
}

%news-card {
  font-size: 18px;
  font-family: var(--mono);
}
