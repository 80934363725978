@import "~scss/imports";

.ProgramHeader {
  padding-top: 3%;
  background-image: linear-gradient(
    to bottom,
    rgba(215, 249, 244, 0.5),
    #ffffff
  );
}
.ProgramHeader-top {
  display: grid;
  grid-template-areas:
    "copy"
    "image1"
    "bottom";

  .ProgramHeader-link {
    display: none;
  }
}

.ProgramHeader-details {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 10%;
  margin-top: calc(100px + 10%);
}

.ProgramHeader-attributelist {
  margin-top: 2em;
}

.ProgramHeader-link {
  margin-top: 2em;
  display: grid;
  grid-gap: 1em;
  justify-items: start;

  @include tablet {
    margin-top: calc(100px + 10%);
  }
}

.ProgramHeader-copy {
  grid-area: copy;
  display: grid;
  padding-left: 5%;
  margin-top: 1em;
}

.ProgramHeader-label {
  @extend %label;
  padding: 0.6em 1.2em;
  justify-self: start;
}

.ProgramHeader-heading {
  @extend %h1caps;

  margin-top: 1rem;
}

.ProgramHeader-text {
  @extend %p;
  margin-top: 1em;

  width: 80%;
}

.ProgramHeader-image1 {
  grid-area: image1;
  margin-right: 30%;
  margin-top: 1em;
  @include tablet {
    margin-right: 0;
  }
}
.ProgramHeader-image2 {
  position: relative;
  margin-left: 120px;
  margin-top: -5%;

  @include tablet {
    margin-left: 0;
    margin-top: 0;
  }
}
.ProgramHeader-badge {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 200px;
  height: 200px;
  transform: translate(-50%, 50%);
}

.ProgramHeader-subheading {
  @extend %h2blackcaps;
}
.ProgramHeader-subtext {
  @extend %plarge;
  margin-top: 1em;
}

@include tablet {
  .ProgramHeader-top {
    grid-template-areas:
      "image1 copy copy"
      "image1 . bottom";
    grid-template-columns: 2fr 1fr 40%;
    position: relative;

    .ProgramHeader-link {
      display: grid;
    }

    .ProgramHeader-top-bottom {
      grid-area: bottom;
      position: absolute;
      margin-top: 7%;
    }
    .ProgramHeader-image1 {
      margin-top: 0;
    }
  }
  .ProgramHeader-details {
    max-width: calc(60% - 100px);
    margin-top: 9%;
    padding-left: 2%;
    padding-right: 2%;

    .ProgramHeader-link {
      display: none;
    }
  }
}

@media (min-width: 1400px) {
  .ProgramHeader-top {
    grid-template-columns: 2fr 1fr 50%;
  }
  .ProgramHeader-details {
    max-width: calc(50% - 100px);
    padding-left: 6%;
  }
}
