@import "~scss/imports";

.ArrowButton {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  display: inline-flex;
  align-items: center;
  justify-self: start;

  @extend %ctalink;

  > svg {
    margin-left: 1rem;
  }
}
