@import "~scss/imports";

.NewsLanding {
  @extend %blockmarginvertical;
  @extend %max;
  padding-top: 2em;
}

.NewsLanding-load {
  margin-top: 2em;
  display: flex;
  justify-content: center;
}

.NewsLanding-load-message {
  @extend %label;
}
