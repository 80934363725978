@import "~scss/imports";

.HeadingTextLinkImage {
  --max-image: 300px;

  display: grid;
  grid-template-areas:
    "heading"
    "image"
    "text"
    "link";
  align-items: start;

  @include tablet {
    grid-template-areas:
      "heading . image"
      "text . image"
      "link . image";
    grid-template-rows: auto auto 1fr;
    grid-template-columns: auto 1fr minmax(30%, var(--max-image));
    grid-column-gap: 4em;

    &.right {
      grid-template-areas:
        "image . heading"
        "image . text"
        "image . link";
      grid-template-columns: minmax(30%, var(--max-image)) 1fr auto;
    }
  }

  @extend %blockmarginvertical;
  @extend %max;
}

.HeadingTextLinkImage-heading {
  grid-area: heading;

  span {
    @extend %h2blackcaps;
  }

  .subHeader {
    margin: 25px 0 10px;

    span {
      @extend %plargebold;
      text-transform: none;
    }
  }
}

.HeadingTextLinkImage-text {
  @extend %p;
  grid-area: text;
  max-width: 30em;
  //margin-right: var(--module-padding);
}

.HeadingTextLinkImage-heading,
.HeadingTextLinkImage-link {
  max-width: 40rem;
}

.HeadingTextLinkImage-link {
  grid-area: link;
}

.HeadingTextLinkImage-image {
  grid-area: image;
  padding: 1rem 0;
  max-width: var(--max-image);

  @include tablet {
    padding: 0;
  }
}
