@import "variables";

html:has(#root .AppPrivacyCenter),
body:has(#root .AppPrivacyCenter)
{
  overscroll-behavior: none;
}

html:has(#root .App.SmoothScrollDisabled),
body:has(#root .App.SmoothScrollDisabled)
{
  overscroll-behavior: none;
}

button {
  cursor: pointer;
}

.position--center {
  display: flex;
  justify-content: center;
}
.position--right {
  display: flex;
  justify-content: flex-end;
}

.rte {
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  a {
    color: var(--teal4);
    text-decoration: none;
  }
}

a:focus {
  outline: 0;
}
a:focus:not(:hover) {
  outline: 2px solid var(--teal2);
}
