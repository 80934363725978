@import "~scss/imports";
$breakpoint: 950px;
.ProgramApplicationSip {
  display: grid;
  grid-template-areas:
    "heading "
    "text"
    "partners"
    "link";
  grid-template-columns: 1fr;
  grid-gap: 2em;

  @media (min-width: 600px) {
    grid-template-areas:
      "heading heading"
      "text text"
      "partners link";
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em 5em;
  }

  @media (min-width: $breakpoint) {
    grid-template-areas:
      "heading partners"
      "text link";
    grid-template-columns: 1fr auto;
    grid-gap: 2em 10vw;
    align-items: start;
    padding-right: 2vw;
  }
}

.ProgramApplicationSip-heading,
.ProgramApplicationSip-text {
  max-width: 32rem;
  @media (min-width: $breakpoint) {
    margin-left: auto;
    margin-right: auto;
  }
}
.ProgramApplicationSip-heading {
  @extend %h2blackcaps;
  grid-area: heading;
}

.ProgramApplicationSip-text {
  @extend %plarge;
  grid-area: text;
}

.ProgramApplicationSip-partners {
  grid-area: partners;

  img {
    max-width: 350px;
    width: 100%;
  }
}

.ProgramApplicationSip-link {
  grid-area: link;
  align-self: center;

  @media (min-width: $breakpoint) {
    //margin-top: 4em;
  }
}
