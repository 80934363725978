@import "~scss/global";

html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}
body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: var(--roboto);
  color: var(--black);
}

code {
  font-family: var(--roboto-mono);
}

img {
  display: block;
  width: 100%;
  height: auto;
}
