@import "~scss/imports";

%quoteimage {
  font-size: var(--size-quoteimage);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}

.ProfileCtaWithImage {
  --gutter: 16px;
  --bg: var(--blue1);
  --size-quoteimage: 24px;

  @extend %max;
  padding: 0;
  @extend %blockmarginvertical;

  position: relative;

  @media (min-width: 700px) {
    @include padding;

    .ProfileCtaWithImage-inner {
      padding-top: calc(var(--gutter) / 2);
      padding-right: var(--gutter);
    }

    .ProfileCtaWithImage-inner-inner {
      grid-template-areas: "text image";
      grid-template-columns: 3fr auto;
      align-items: center;
    }

    .ProfileCtaWithImage-text {
      --pad: calc(2 * var(--gutter));
      background-color: var(--bg);
      padding: var(--pad) var(--pad) var(--pad) calc(1.5 * var(--pad));
      margin-top: unset;
      height: 100%;
    }
    .ProfileCtaWithImage-image {
      left: calc(-2 * var(--gutter));
      max-width: 20em;
    }
    .ProfileCtaWithImage-wave-mobile {
      left: calc(2 * var(--gutter));
    }
  }

  @media (min-width: 1300px) {
    --gutter: 30px;
    --size-quoteimage: 30px;

    .ProfileCtaWithImage-inner-inner {
      background-color: var(--bg);

      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
      grid-template-areas: "text image";
      align-items: center;
    }

    .ProfileCtaWithImage-image {
      padding-left: 0;
      padding-top: 0;
      max-width: 100%;
      margin-left: unset;
      margin-right: unset;
      left: 0;
    }
    .ProfileCtaWithImage-wave-mobile {
      display: none;
    }
    .ProfileCtaWithImage-wave-desktop {
      display: block;
      left: var(--gutter);
      width: calc(100% - var(--gutter));
    }
    .ProfileCtaWithImage-text {
      padding: var(--gutter);
    }
  }
}

.ProfileCtaWithImage-wave-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - var(--gutter));
  height: calc(100% - var(--gutter));
  z-index: -1;
}
.ProfileCtaWithImage-wave-desktop {
  position: absolute;
  width: calc(100% - var(--gutter));
  height: calc(100% - var(--gutter));
  overflow: hidden;
  z-index: -1;
  top: 0;
  display: none;
}

.ProfileCtaWithImage-inner {
  padding-top: var(--gutter);
  position: relative;
}
.ProfileCtaWithImage-inner-inner {
  position: relative;

  display: grid;
  grid-template-areas:
    "image"
    "text";

  > * {
    position: relative;
  }
}

.ProfileCtaWithImage-image {
  grid-area: image;
  padding-left: var(--gutter);
  padding-top: var(--gutter);
  position: relative;
  z-index: 1;
}

.ProfileCtaWithImage-text {
  grid-area: text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: calc(2 * var(--gutter)) var(--gutter) calc(4 * var(--gutter));
  margin-top: calc(-1 * var(--gutter));
  background-color: var(--bg);
}

.ProfileCtaWithImage-heading {
  @extend %quoteimage;
  position: relative;
}
.ProfileCtaWithImage-attribution {
  grid-area: attribution;
  margin-top: 1em;
}
.ProfileCtaWithImage-link {
  grid-area: link;
  margin-top: 2em;
}
