@import "~scss/imports";

.WaysToGetInvolved {
  list-style-type: none;
  @extend %max;
  @extend %line-list;
}

.WaysToGetInvolved-item {
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;
}

.WaysToGetInvolved-item-heading {
  @extend %h4;
}

.WaysToGetInvolved-item-overview {
  @extend %p;
  margin-top: 1.25em;
  @media (min-width: 1200px) {
    min-height: 5.5em;
  }
}

.WaysToGetInvolved-item-commitmentlevel {
  @extend %plargebold;
  margin-top: 1em;
}

.WaysToGetInvolved-item-commitmentmore {
  @extend %psmall;
  margin-bottom: 3em;
  margin-top: 0.5em;
}

.WaysToGetInvolved-item-link {
  margin-top: auto;
}
