@import "~scss/imports";
@import "mobile-nav";

.Nav {
  --gradient: linear-gradient(
    to right,
    var(--blue1) -10%,
    var(--orange1) 21%,
    var(--blue1) 93%
  );
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  min-height: var(--nav-height);

  display: flex;
  flex-direction: column;
  a {
    color: inherit;
    text-decoration: none;
  }
  ul {
    list-style-type: none;
  }
  .Nav-inner > * {
    flex-shrink: 0;
  }
}
.Nav-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  
  @media all and (-ms-high-contrast: none) {
    background-image: linear-gradient(90deg, rgb(229, 248, 255) -10%, rgb(255, 243, 232) 21%, rgb(229, 248, 255) 93%);
  }
}
.Nav-backgrounded
{

  --gradient: linear-gradient(
                  to left,
                  var(--blue1) -10%,
                  var(--orange1) 21%,
                  var(--blue1) 93%
  );

  background-image: var(--gradient);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.Nav-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: var(--gradient);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
}
.Nav:focus-within .Nav-wrapper:before,
.Nav--scrolled .Nav-wrapper:before {
  opacity: 0.99;
}
.Nav-inner {
  @extend %max;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  width: 100%;

  &.fr-page {
    .Nav-links-item {
      margin: 0 1em;

      a {
        font-size: 15px;
      }
    }

    .Nav-subscribe-toggle {
      font-size: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.Nav-logo {
  width: 120px;
  padding: 5px;
  flex-shrink: 0;
  svg {
    width: 100%;
  }
}

.Nav-links {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.Nav-links-item {
  margin: 0 2em;
  transition: opacity 0.3s;
  a {
    font-weight: bold;
  }
}
.Nav-links-item--inactive {
  opacity: 0.28;
}

.Nav-links-item-subnav {
  position: absolute;
  padding: 0;
  margin: 0;
  padding-bottom: 1em;
}

.Nav-links-item-subnav-item {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.Nav-openbg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  background-image: var(--gradient);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.Nav-subscribe {
  position: absolute;
  top: calc(100% + 1em);
  right: 0;
  width: 100%;
  padding: 1em 2rem;

  //horizontal Subscribe
  .SubscribeSuccess {
    justify-content: center;
  }
}
.Nav-subscribe-toggle {
  margin-left: auto;
  @extend %button;
  @extend %label;
  min-width: 0;
  font-weight: bold;

  &.Nav-subscribe-toggle--active {
    background-color: var(--teal2);
  }
}

.DesktopNav {
  a {
    @extend %navitem;
    &:hover {
      color: var(--teal3);
    }
  }
}
