@import "~scss/imports";

.NewsArticle {
}

.NewsArticle-header {
  background-color: var(--teal1);
  clip-path: url(#HeaderWaveClip);
}

.NewsArticle-header-heading {
  padding: 12vh 1em 22vh;

  @extend %h1;
  text-align: center;
  @extend %max;
  //padding: 0 1em;
}

.NewsArticle-body {
  @extend %maxarticle;

  .Text {
    @extend %plarge;
  }
}
