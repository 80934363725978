@import "~scss/imports";

.PullquoteSimple {
  @extend %maxarticle;
  padding-top: 2em;
  padding-bottom: 2em;

  h2 {
    @extend %h3caps;
  }

  blockquote {
    @extend %campaignquote;
    margin: 0;
  }

  p {
    @extend %p;
    text-transform: uppercase;
  }
}
