@import "~scss/imports";

.RichText {
  @extend %blockmarginvertical;
  @extend %max;

  h1 {
    @extend %h1;
  }
  h2 {
    @extend %h2;
  }
  h3 {
    @extend %h3;
  }
  h4 {
    @extend %h4;
  }

  p {
    @extend %p;
  }

  pre {
    @extend %pmono;
  }

  a {
    @extend %ctalink;
    font-size: inherit;
  }

  blockquote {
    @extend %bigquote;
    @extend %campaignquote;
  }
}
