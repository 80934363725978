@import "~scss/imports";

.ProgramDetail {
  @extend %maxmobile;
  @extend %modulepadvertical;
  display: grid;

  grid-template-areas:
    "heading"
    "text"
    "attributes"
    "link";
  grid-row-gap: 1em;

  @include desktop {
    grid-template-areas:
      "heading attributes"
      "text attributes"
      "link attributes";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1fr;
    grid-column-gap: 50px;

    .ProgramDetail-attributelist {
      justify-self: center;
    }
  }
}

.ProgramDetail-heading {
  grid-area: heading;
  @extend %h2blackcaps;
}

.ProgramDetail-text {
  grid-area: text;
  @extend %plarge;
  margin-top: 1em;
}

.ProgramDetail-link {
  grid-area: link;
  margin-top: 2em;
}

.ProgramDetail-attributelist {
  grid-area: attributes;
}
