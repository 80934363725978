@import "~scss/imports";

.MultipleLinks {
  display: grid;
  grid-gap: 1em;
}

.MultipleLinks-secondary {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  .ArrowLink {
    margin-bottom: 0.5em;
  }
}
