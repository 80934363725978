@import "~scss/imports";

.ProgramComparison {
  @extend %max;
}
.ProgramComparison-inner {
  @extend %line-list;
}

.ProgramComparisonItem {
  display: grid;
  position: relative;
  grid-template-areas:
    "icon"
    "heading "
    "text"
    "attributes"
    "link";
  grid-row-gap: 1em;

  .ProgramComparisonItem-attributelist,
  .ProgramComparisonItem-link {
    margin-top: 2em;
  }

  @include tablet {
    grid-template-areas:
      "icon heading heading"
      "text text attributes"
      "link link attributes";
    grid-template-columns: auto 1fr 50%;
    grid-template-rows: auto auto 1fr;
    grid-column-gap: 1em;

    .ProgramComparisonItem-attributelist,
    .ProgramComparisonItem-link {
      margin-top: 0;
    }

    .ProgramComparisonItem-heading {
      align-self: center;
    }
    .ProgramComparisonItem-link {
      align-self: end;
    }
  }

  @include desktop {
    grid-template-areas:
      "icon"
      "heading "
      "text"
      "attributes"
      "link";

    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr auto;
    padding-top: var(--margin);
    padding-bottom: var(--margin);

    .ProgramComparisonItem-attributelist,
    .ProgramComparisonItem-link {
      margin-top: 2em;
    }
  }
}

.ProgramComparisonItem-icon {
  max-width: 120px;
  grid-area: icon;
  display: block;
}

.ProgramComparisonItem-heading {
  @extend %programheading;
  grid-area: heading;
}

.ProgramComparisonItem-text {
  @extend %p;
  grid-area: text;
  margin-top: 1em;
  align-self: start;
}

.ProgramComparisonItem-attributelist {
  grid-area: attributes;
}

.ProgramComparisonItem-link {
  grid-area: link;
}
