@import "~scss/imports";

%reset-button {
  background: unset;
  border: 0;
  border-radius: 0;
  padding: 0;
}
.BrowseLocales {
  padding: 0 1em 1em;

  .AccordionItem {
    .ButtonLink {
      margin: 2em 0;
      width: 100%;
    }
  }
  .AccordionItem-heading {
    @extend %h4;
    background-color: var(--white);
    z-index: 2;
    padding-left: 0;
  }
}

.BrowseLocales-heading {
  @extend %formlabel-mono;
  margin-bottom: 1em;
}

.BrowseLocales-list {
  column-count: 2;

  list-style-type: none;
  padding: unset;
  margin: unset;
  margin-top: 1em;
}

.BrowseLocales-list-item {
  @extend %reset-button;
  @extend %p;
  text-align: unset;
  display: block;
  padding: 0.5em 0;

  &:hover {
    color: var(--teal3);
  }
  &:focus {
    outline: 2px solid var(--teal3);
  }
}
