@import "~scss/imports";

.ClubApplication {
}

.ClubApplication-inner {
  @extend %max;
  @extend %blockpaddingvertical;
}

.ClubApplication-heading {
  @extend %h3caps;
}

.ClubApplication-text {
  @extend %p;
  margin-top: 1em;
}

.ClubApplication-requirements {
  margin-top: 3em;
  column-count: 2;

  li {
    break-inside: avoid;
  }
}

.ClubApplication-link {
  margin-top: 3em;
}
