@import "~scss/imports";

.AccordionItem {
  border-top: 2px solid var(--teal2);
}

.AccordionItem-heading {
  background-color: transparent;
  border: 0;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--teal3);
    outline: 0;
    svg {
      stroke: var(--teal2);
      * {
        stroke: inherit;
      }
    }
  }
}

.AccordionItem-heading-carrot {
  width: 0.7rem;
  transition: transform 0.3s;
  .AccordionItem--open & {
    transform: rotate(90deg);
  }
}
