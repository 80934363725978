@import "~scss/imports";

.AlertBar {
  text-align: center;
  background-color: var(--teal3);
  color: var(--white);
  padding: 0.25em;
  @extend %alertbartext;
  position: relative;
  white-space: nowrap;
}
