.PlacesAutocomplete-input {
  position: relative;
  input {
    padding-right: 26px;
  }

  img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
  }
}
